export const KeyCodes = {
    ENTER: 13,
    ESCAPE: 27,
    BACKSPACE: 8,
    I: 73,
    J: 74,
    C: 67,
    U: 85,
    F12: 123
};
