import ExitEnter from "../../ExitEnter";
import Navigation from "../../Navigation";
import Cursor from "../../Cursor";

const exitEnter = new ExitEnter();
const navigation = new Navigation();
const cursor = new Cursor();

export default class initBarbaErrorPage {

    constructor() {
        this.page = this.extend();
        this.page.init();
    }

    extend() {
        return Barba.BaseView.extend({
            namespace: 'error-page',
            onEnter() {
                navigation.init();
                },
            onEnterCompleted() {
                //navigation.fixActiveElement();
                //sendPageView();
                cursor.init();
            },
            onLeave() {
                exitEnter.exit();
            },
            onLeaveCompleted() {
            },
        });
    }
}
