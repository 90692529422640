export default class WordChanger {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            wordChanger: '.js-word-changer',
            wordInsert: '.js-word-insert',
            wordWireText: '.js-wire-text',
            wordSet: '.js-word-set',
        };

        this.defaults = Object.assign({}, _defaults, options);

        this.timing = false;
        this.timerIndex = null;
        this.count = 1;
        //this.target = this.getWordInsert();
        //this.els = this.getWordSet();
        //this.els_len = this.els.length;
        if (this.getWordSet().length === 1) {
            this.count = 0;
        }
    }

    init() {
        this.start();
    }

    start() {
        var _this = this;

        setTimeout(function(){
            _this.getWordWideText().removeClass('show');
            setTimeout(function(){
                _this.getWordWideText().addClass('show');
            }, 500);
        }, 2500);
        _this.getWordWideText().removeClass('show');

        if (this.getWordInsert().length !== 0 && this.getWordSet().length !== 0) {
            this.timing = true;
            this.timerIndex = setTimeout(function(){
                _this.getWordInsert().addClass('show-off').removeClass('show-on');
                setTimeout(function(){
                    _this.getWordInsert().html($(_this.getWordSet()[_this.count]).clone(true));
                    var n = _this.getWordInsert();

                    n.offset();
                    n.removeClass('show-off').addClass('show-on');
                    n.find(_this.defaults.wordWireText).removeClass('show').addClass('show');
                    _this.count++;

                    if (_this.count === _this.getWordSet().length) {
                        _this.count = 0;
                    }

                    _this.start();
                },500)
            },5000)
        }
    }

    stop() {
        clearTimeout(this.timerIndex);
        this.timing = false;
    }

    getWordChanger() {
        return $(this.defaults.wordChanger);
    }

    getWordInsert() {
        return this.getWordChanger().find(this.defaults.wordInsert);
    }

    getWordSet() {
        return this.getWordChanger().find(this.defaults.wordSet).children();
    }

    getWordWideText() {
        return this.getWordChanger().find(this.defaults.wordWireText);
    }
}