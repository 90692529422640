import { gsap } from "gsap";

export default class ExitEnter {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            exitEnterEl: '.js-exit-enter-el',
        };

        this.defaults = Object.assign({}, _defaults, options);

        this.exit();
        this.enterSet();
        this.enter();
    }

    init() {
        //console.log('ExitEnter init()');
    }

    getExitEnterEl() {
        return $(this.defaults.exitEnterEl);
    }

    exit() {
        if (this.getExitEnterEl().length > 0) {
            gsap.to(this.getExitEnterEl(), {
                duration: 0.2,
                //stagger: 0.05,
                autoAlpha: 0,
            });
        }
    }

    enterSet() {
        if (this.getExitEnterEl().length > 0) {
            gsap.set(this.getExitEnterEl(), {
                autoAlpha: 0,
                //left: -10,
            }, 0.1);
        }
    }

    enter(animationDelay) {
        if (this.getExitEnterEl().length > 0) {
            gsap.to(this.getExitEnterEl(), {
                duration: 0.4,
                //stagger: -0.1,
                autoAlpha: 1,
                //left: 0,
                delay: animationDelay,
                onComplete: () => {
                    gsap.set(this.getExitEnterEl(), {
                        clearProps: 'all',
                    });
                },
            });
        }
    }
}
