import { TweenMax, TimelineMax, Power0 } from 'gsap'
import ScrollMagic from "scrollmagic";
//import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
//ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export default class FeaturedWork {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            featuredWorkList: '.js-featured-work-list',
            featuredWorkItem: '.js-featured-work-item',
            featuredWorkPreview: '.js-featured-work-preview',
            featuredWorkPreviewItem: '.js-featured-work-preview-item',
            featuredWorkPreviewBg: '.js-featured-work-preview-bg',
            featuredWorkPreviewList: '.js-featured-work-preview-list',
            previewList: '.js-preview-list',
            previewListImage: '.js-featured-work-preview-image',
        };

        this.defaults = Object.assign({}, _defaults, options);
    }

    init() {
        //console.log('FeaturedWork init()');

        if (this.getFeaturedWorkList().length > 0) {

            const firstListItemColor = this.getFeaturedWorkItem().first().find('*[data-work-color]').data('work-color');
            this.getFeaturedWorkPreviewBg().css('background-color', firstListItemColor);

            this.pinPreviewList();
            this.scrollPreviewList();
        }
    }

    // region getters

    getFeaturedWorkList() {
        return $(this.defaults.featuredWorkList);
    }

    getFeaturedWorkItem() {
        return $(this.defaults.featuredWorkItem);
    }

    getFeaturedWorkPreview() {
        return $(this.defaults.featuredWorkPreview);
    }

    getFeaturedWorkPreviewBg() {
        return $(this.defaults.featuredWorkPreviewBg);
    }

    getFeaturedWorkPreviewItem() {
        return $(this.defaults.featuredWorkPreviewItem);
    }

    getFeaturedWorkPreviewList() {
        return $(this.defaults.featuredWorkPreviewList);
    }

    getPreviewListImage() {
        return $(this.defaults.previewListImage);
    }

    // endregion

    // region methods

    pinPreviewList() {
        const controller = new ScrollMagic.Controller();
        const scrollDuration = `${((((this.getFeaturedWorkItem().length)) - 1) * 100 + 25)}%`;

        const scene = new ScrollMagic.Scene({
            triggerElement: this.defaults.featuredWorkPreviewList,
            duration: scrollDuration,
            triggerHook: 'onLeave',
        })
            .setPin(this.defaults.featuredWorkPreviewList)
            // .addIndicators({
            //     name: `pin duration: ${scrollDuration}`,
            // })
            .addTo(controller);
    }

    scrollPreviewList() {
        const controller = new ScrollMagic.Controller();
        const scrollLeft = `${((((this.getFeaturedWorkItem().length)) * 2) * 100)}%`;
        const scrollDuration = `${(this.getFeaturedWorkItem().length)}00%`;

        const colorChangeDelay = (1 / (this.getFeaturedWorkItem().length * 2)) / 2;
        const colorChangeDuration = (1 / this.getFeaturedWorkItem().length);

        const listContainer = this.getFeaturedWorkPreviewList().find(this.defaults.previewList);

        const scrollLeftTween = TweenMax.to(listContainer, 1, {
            x: `-${scrollLeft}`,
            ease: Power0.easeNone,
        });

        const changeColorsTween = new TimelineMax({
            delay: colorChangeDelay,
        });

        for (const color of this.getFeaturedWorkPreviewItem()) {
            const nextColor = $(color).data('next-bg-color');

            changeColorsTween.add(TweenMax.to(this.getFeaturedWorkPreviewBg(), colorChangeDuration, {
                backgroundColor: nextColor,
                ease: Power0.easeNone,
            }));
        }

        const scene = new ScrollMagic.Scene({
            triggerElement: this.defaults.featuredWorkPreview,
            duration: scrollDuration,
            triggerHook: 0.25,
            // triggerHook: 'onLeave',
        })
            .setTween([scrollLeftTween, changeColorsTween])
            // .addIndicators({
            //     name: `scroll left duration: ${scrollDuration}`,
            // })
            .addTo(controller);
    }

    // endregion
}
