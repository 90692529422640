export default function registerEaseOutExpo () {
    jQuery.extend(
        jQuery.easing, {
            easeOutExpo(x, t, b, c, d) {
                return (t === d) ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b;
            },
        },
    );

}
