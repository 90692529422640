import { TweenMax, Sine, gsap } from 'gsap'
import '../bundles/jquery.cookie'

export default class CookiesPlugin {
    /**
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            cookieBar: '.js-cookie-bar',
            cookieAccept: '.js-cookie-accept',
            cookieSetting: '.js-cookie-setting',
            cookieSettingContainer: '.js-cookie-setting-container',
            cookieSaveSetting: '.js-cookie-save-setting',
            cookieSettingOption: '.js-cookie-setting-option',
        };

        this.defaults = Object.assign({}, _defaults, options);
        this.init();
    }

    getCookieBar() {
        return $(this.defaults.cookieBar);
    }

    getCookieAccept() {
        return $(this.defaults.cookieAccept);
    }

    getCookieSetting() {
        return $(this.defaults.cookieSetting);
    }

    getCookieSettingOption() {
        return $(this.defaults.cookieSettingOption);
    }

    getCookieSettingOptionByName(name) {
        return $(this.defaults.cookieSettingOption).filter('*[data-name="'+name+'"]');
    }

    getCookieSaveSetting() {
        return $(this.defaults.cookieSaveSetting);
    }

    getCookieSettingContainer() {
        return $(this.defaults.cookieSettingContainer);
    }

    getAllCookiesNames() {
        return [
            'crealab_cookies_required',
            'crealab_cookies_analytic',
            'crealab_cookies_personalized_advertising',
            'crealab_cookies_3rd',
        ];
    }

    getCookiesSubjectToConsent() {
        return {
            crealab_cookies_required: ['PHPSESSID'],
            crealab_cookies_analytic: ['_fbp', '_ga', '_gid', '_gat_gtag_'],
            crealab_cookies_personalized_advertising: [],
            crealab_cookies_3rd: [],
        }
    }

    getCookiesSubjectToConsentByParentCookieName(cookieName) {
        return this.getCookiesSubjectToConsent()[cookieName];
    }

    getRequiredCookiesNames() {
        return [
            'crealab_cookies_required',
        ];
    }

    init() {
        this.initCookiesConsents();
        this.setCookieOptionButtonStates();

        let height = this.getCookieSettingContainer().outerHeight();

        gsap.set(this.getCookieBar(), {
            duration: 0.4,
            autoAlpha: 1,
            bottom: '-'+height+'px',
            ease: Sine.easeInOut,
        });

        let _this = this;
        this.blockCookies();
        let blockCookieTimer = setInterval(function (){
            _this.blockCookies();
        }, 1000);

        this.getCookieAccept().on('click', (ev) => {
            ev.preventDefault();

            let _this = this;

            this.getAllCookiesNames().forEach(function(cookieName, index) {
                _this.setCookies(cookieName, 1);
            });

            this.closeCookies('crealab_cookies_consent', new Date().toUTCString());
        });

        this.getCookieSaveSetting().on('click', (ev) => {
            ev.preventDefault();

            this.closeCookies('crealab_cookies_consent', new Date().toUTCString());
        });

        this.getCookieSettingOption().on('click', (ev) => {
            ev.preventDefault();

            const name = $(ev.currentTarget).attr('data-name');
            const value = $(ev.currentTarget).attr('data-value');

            $(ev.currentTarget).addClass('is-active');
            $(ev.currentTarget).siblings('span.c-button').removeClass('is-active');

            this.setCookies(name, value);
            this.blockCookies();
        });

        this.getCookieSetting().on('click', (ev) => {
            ev.preventDefault();
            this.toggleCookieBar();
        })

        if (this.hasConsentAdded() || this.isFacebookApp()) {
            // They've been here before.
            this.getCookieBar().remove();
        }
    }

    initCookiesConsents() {
        let _this = this;

        if (!this.hasConsentAdded()) {
            this.getAllCookiesNames().forEach(function(cookieName, index) {
                if ($.inArray(cookieName, _this.getRequiredCookiesNames()) > -1) {
                    _this.setCookies(cookieName, 1);
                } else {
                    _this.setCookies(cookieName, 0);
                }
            });
        }
    }

    hasConsentAdded() {
        let _this = this;

        let consent = this.getCookies('crealab_cookies_consent');

        // check also other cookies, if other cookies is enabled, automatically set consent for remove cookie bar
        let allCookiesEnabled = true;
        this.getAllCookiesNames().forEach(function(cookieName, index) {
            allCookiesEnabled = allCookiesEnabled === true ?_this.getCookies(cookieName) === '1' : false;
        });

        let isConsent = (consent && consent !== '' && typeof consent !== 'undefined') || allCookiesEnabled;

        if (allCookiesEnabled && (!consent || consent === '')) {
            this.setCookies('crealab_cookies_consent', new Date().toUTCString());
        }

        return isConsent;
    }

    setCookieOptionButtonStates() {
        let _this = this;

        this.getAllCookiesNames().forEach(function(cookieName, index) {
            let cookieValue = _this.getCookies(cookieName);
            let cookieOptions = _this.getCookieSettingOptionByName(cookieName);
            if (cookieValue == 1) {
                cookieOptions.filter('[data-value="1"]').addClass('is-active');
                cookieOptions.filter('[data-value="0"]').removeClass('is-active');
            } else {
                cookieOptions.filter('[data-value="1"]').removeClass('is-active');
                cookieOptions.filter('[data-value="0"]').addClass('is-active');
            }
        });

    }

    toggleCookieBar() {
        let isOpen = this.getCookieBar().hasClass('is-open');
        if (isOpen) {
            let height = this.getCookieSettingContainer().outerHeight();
            gsap.to(this.getCookieBar(), {
                bottom: '-'+height+'px',
                onStart: () => {
                    this.getCookieBar().removeClass('is-open');
                }
            });
        } else {
            gsap.to(this.getCookieBar(), {
                bottom: 0,
                onStart: () => {
                    this.getCookieBar().addClass('is-open');
                }
            });
        }
    }

    isFacebookApp() {
        const ua = navigator.userAgent || navigator.vendor || window.opera;
        return(ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    }

    getCookies(name) {
        // document.cookie.indexOf("crealab_cookie_acceptance")
        return $.cookie(name);
    }

    setCookies(name, value) {
        $.cookie(name, value, {
            path: '/',
            expires: 365,
        });
    }

    removeCookies(name) {
        $.cookie(name, '', { path: '/', expires: -1 });
        $.removeCookie(name, { path: '/', domain: document.domain });
    }

    blockCookies() {
        let _this = this;
        this.getAllCookiesNames().forEach(function(cookieName, index) {
            let cookieValue = _this.getCookies(cookieName);
            if (cookieValue == 0) {
                _this.getCookiesSubjectToConsentByParentCookieName(cookieName).forEach(function(cookieNameToBlock, index) {
                    $.each($.cookie(), function (name, value) {
                        if (name.substring(0, cookieNameToBlock.length) === cookieNameToBlock) {
                            _this.removeCookies(name);
                        }
                    });
                });
            }
        });
    }

    closeCookies(name, value) {
        this.setCookies(name, value);

        TweenMax.to(this.getCookieBar(), {
            duration: 0.4,
            y: 40,
            autoAlpha: 0,
            ease: Sine.easeInOut,
            onComplete: () => {
                this.getCookieBar().remove();
                window.location.reload();
            },
        });
    }
}
