import ImagesAnimation from "../../ImagesAnimation";
import FeaturedWork from "../../FeaturedWork";
import ChangeColor from "../../ChangeColor";
import ExitEnter from "../../ExitEnter";
import Navigation from "../../Navigation";
import Parallax from "../../Parallax";
import OnScrollAnimations from "../../OnScrollAnimations";
import LazyLoad from "vanilla-lazyload";
import ClipNavigation from "../../ClipNavigation";
import Cursor from "../../Cursor";
import WordChanger from "../../WordChanger";
import Forms from "../../Forms";
import ContactForm from "../../ContactForm";
import MorphingButton from "../../MorphingButton";
import TestimonialLetterAnimationSlider3 from "../../TestimonialLetterAnimationSlider3";
//import HideOnScrollDown from "../../HideOnScrollDown";

const morphingButton = new MorphingButton();
const colorChange = new ChangeColor();
const exitEnter = new ExitEnter();
const navigation = new Navigation();
const parallax = new Parallax();
const onScrollAnimations = new OnScrollAnimations();
const clipNavigation = new ClipNavigation();
//const cursor = new Cursor();
const imagesAnimation = new ImagesAnimation();
const testimonialLetterAnimationSlider = new TestimonialLetterAnimationSlider3();
const wordChanger = new WordChanger();
const featuredWork = new FeaturedWork();
const forms = new Forms();
const contactForm = new ContactForm();
//const hideOnScrollDown = new HideOnScrollDown();

const lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy-load"
});

export default class initBarbaHomepage {

    constructor() {
        this.page = this.extend();
        this.page.init();
    }

    extend() {
        return Barba.BaseView.extend({
            namespace: 'homepage',
            onEnter() {
                colorChange.destroy();
                onScrollAnimations.destroy();
                parallax.destroy();
                navigation.init();
                clipNavigation.clipNavigation();
                lazyLoadInstance.update();
            },
            onEnterCompleted() {
                colorChange.attach();
                onScrollAnimations.attach();
                parallax.attach();
                //cursor.init();
                morphingButton.init();
                testimonialLetterAnimationSlider.init();
                imagesAnimation.init();
                wordChanger.init();
                featuredWork.init();
                forms.init();
                contactForm.init();
                //hideOnScrollDown.init();

                //sendPageView();
            },
            onLeave() {
                exitEnter.exit();
            },
            onLeaveCompleted() {

            },
        });
    }
}
