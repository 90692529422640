import { TweenMax, TimelineMax, Quad } from 'gsap'
import ScrollMagic from "scrollmagic";
//import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
//ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export default class ImagesAnimation {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            canvasWrapper: '.js-distort-canvas',
        };

        this.defaults = Object.assign({}, _defaults, options);
    }

    init() {
        if (this.getCanvasWrapper().length > 0) {
            this.initPixi();
        }
    }

    // region Getters

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    // getCanvasWrapper() {
    //     return $(this.defaults.canvasWrapper);
    // }

    getCanvasWrapper() {
        return document.querySelectorAll(this.defaults.canvasWrapper);
    }

    // endregion

    // region Methods

    resetCanvas(wrapper) {
        TweenMax.set(wrapper, {
            autoAlpha: 0,
            scale: 1.1,
        });
    }

    initPixi() {
        this.resetCanvas(this.getCanvasWrapper());

        // STAGGER DELAY
        const delay = 0.125;

        const delays = {};

        this.resetCanvas(this.getCanvasWrapper());

        for (const canvasWrapper of this.getCanvasWrapper()) {
            const $canvasWrapper = $(canvasWrapper);

            const group = $canvasWrapper.attr('data-group');
            if (delays[group] === undefined) {
                delays[group] = delay;
            }

            let animationTrigger = $canvasWrapper.attr('data-animation-trigger');

            if (animationTrigger === undefined) {
                animationTrigger = 0.75;
            }

            // CANVAS SIZE
            const canvasHeight = $canvasWrapper.innerHeight();
            const canvasWidth = $canvasWrapper.innerWidth();

            // CREATE PIXI APPLICATION
            const app = new PIXI.Application(
                canvasWidth,
                canvasHeight, {
                    transparent: true,
                },
            );

            // ADD CANVAS TO CANVAS WRAPPER ELEMENT
            canvasWrapper.appendChild(app.view);

            const image = PIXI.Sprite.fromImage($canvasWrapper.data('image'));
            const displacementMap = PIXI.Sprite.fromImage($canvasWrapper.data('displacement-map'));
            const filter = new PIXI.filters.DisplacementFilter(displacementMap);

            image.name = `${$canvasWrapper.data('image')}`;
            image.anchor.set(0.5);
            image.interactive = false;
            image.width = canvasWidth;
            image.height = canvasHeight;
            image.position.set(canvasWidth / 2, canvasHeight / 2);


            displacementMap.name = `${$canvasWrapper.data('displacement-map')}`;
            displacementMap.anchor.set(0.5);
            displacementMap.scale.set(1);
            displacementMap.position.set(canvasWidth / 2, canvasHeight / 2);

            filter.scale.set(30);

            app.stage.filterArea = app.screen;
            app.stage.filters = [filter];
            app.stage.addChild(image, displacementMap);

            const controller = new ScrollMagic.Controller();

            const displaceTl = new TimelineMax({
                delay: delays[group],
                onStart: () => {
                    const img = new Image();
                    img.src = $canvasWrapper.data('image');

                    canvasWrapper.appendChild(img);
                },
                onComplete: () => {
                    app.destroy(true, true);
                },
            });

            displaceTl.add("start")
                .to($canvasWrapper, {
                    duration: 0.6,
                    autoAlpha: 1,
                    onComplete: () => {

                    },
                }, "start")
                .to($canvasWrapper, {
                    duration: 1,
                    scale: 1,
                    ease: Quad.easeOut,
                    onComplete: () => {

                    },
                }, "start")
                .to(displacementMap.scale, {
                    duration: 1.8,
                    x: 5,
                    y: 5,
                    ease: Quad.easeOut,
                    onComplete: () => {

                    },
                }, "start")
                .to(filter.scale, {
                    duration: 1,
                    x: 0,
                    y: 0,
                    ease: Quad.easeOut,
                    onComplete: () => {

                    },
                }, "-=1", "start");

            const scene = new ScrollMagic.Scene({
                triggerElement: canvasWrapper,
                triggerHook: animationTrigger,
            })
                .setTween(displaceTl)
                .addTo(controller);

            scene.reverse(false);

            // INCREMENT STAGGER DELAY
            delays[group] += delay;
        }
    }

    // endregion
}
