import { TweenMax } from 'gsap'

export default class Footer {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            footerPage: '.js-footer-page',
            footerPageBg: '.js-footer-page-bg',
            footerPageCta: '.js-footer-page-cta',
        };

        this.defaults = Object.assign({}, _defaults, options);

        this.init();
        this.footerPageHover();
    }

    // region init

    init() {
        //console.log('Footer init()');
    }

    // endregion

    getFooterPage() {
        return $(this.defaults.footerPage);
    }

    getFooterPageBg() {
        return $(this.defaults.footerPageBg);
    }

    getFooterPageCta() {
        return $(this.defaults.footerPageCta);
    }

    // region getters


    // endregion

    // region methods

    footerPageHover() {
        this.getFooterPageCta().on('mouseenter', (ev) => {
            const footerPageBg = $(ev.currentTarget).closest(this.defaults.footerPage).find(this.defaults.footerPageBg);
            $(ev.currentTarget).closest(this.defaults.footerPage).addClass('is-hovered');

            TweenMax.to(footerPageBg, 3.2, {
                scale: 1.01,
                ease: Power3.easeOut,
            });

            TweenMax.to(footerPageBg, 0.4, {
                autoAlpha: 1,
                ease: Power3.easeOut,
            });
        });

        this.getFooterPageCta().on('mouseleave', (ev) => {
            const footerPageBg = $(ev.currentTarget).closest(this.defaults.footerPage).find(this.defaults.footerPageBg);
            $(ev.currentTarget).closest(this.defaults.footerPage).removeClass('is-hovered');

            TweenMax.to(footerPageBg, 0.4, {
                scale: 1.1,
                autoAlpha: 0,
                ease: Power3.easeOut,
            });
        });
    }

    // endregion
}
