import { gsap, Expo } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin'
gsap.registerPlugin(CSSPlugin);
import 'lettering.js';
import 'jquery-touchswipe';
import SplitText from "../bundles/SplitText";

var isFF;

$.fn.onCustom=function(t,n){
    return this.each((function(i,r){var o=$(r).data("listeners");o&&o[t]&&o[t].push(n)})),this
}

$.fn.triggerCustom=function(t,n){
    return this.each((function(i,r){for(var o=$(r).data("listeners")[t],s=0;s<o.length;s++)o[s]({},n)})),this
}

$.fn.offCustom=function(t){
    return this.each((function(n,i){var r=$(i).data("listeners");r&&r[t]&&(r[t]=[])})),this
}

export default class TestimonialLetterAnimationSlider3 {

    constructor(options) {

        const _defaults = {
            'countOfQuotes': 0,
            'activeQuote': 1,
            'partsCount': 7,
            'quoteAnimating': false,
            'isMobile': false,
            'animationDuration': 500,
            'animationDelay': 200,
            'preparedSlides': [],

            'quoteContainer': '.js-about-quotes',
            'quotePrefixSelector': '#quote-',
            'quoteNavArrowWrapper': '.c-about-quotes__nav-arrows-wrapper',
            'quoteNavLinesWrapper': '.c-about-quotes__nav-lines-wrapper',
            'quoteNavLine': '.c-about-quotes__nav-line',
            'quoteNavArrowLeft': '.c-about-quotes__nav-arrow-left',
            'quoteNavArrowRight': '.c-about-quotes__nav-arrow-right',
            'quoteTextElement': '.c-about-quotes__quote-text',
        }

        this.defaults = Object.assign({}, _defaults, options);

        isFF = $('html').hasClass('is-firefox');
        this.pageLoaded = true;
        this.ww = $(window).width();
        this.activeQuoteElement = null;
        this.containerOffsetLeft = null;
        this.containerWidth = null;

        this.init();
    }

    init() {
        //console.log('init quote slider');

        if (this.getActiveQuote().length) {
            this.defaults.countOfQuotes = this.getQuoteContainer().data('quotes-count');

            this.initEvents();
            this.initNavLines();
            this.initSlider();
        }
    }

    initEvents() {
        $(document).on('mouseenter', '.c-about-quotes__nav-arrows-wrapper', (ev) => this.onArrowsWrapperMouseEnter(ev));
        $(document).on('mouseleave', '.c-about-quotes__nav-arrows-wrapper', (ev) => this.onArrowsWrapperMouseLeave(ev));
        $(document).on('mouseenter', '.c-about-quotes__nav-arrow-hover-area-right', (ev) => this.onArrowHoverAreaRightMouseEnter(ev));
        $(document).on('mouseenter', '.c-about-quotes__nav-arrow-hover-area-left', (ev) => this.onArrowHoverAreaLeftMouseEnter(ev));
        $(document).on('mouseleave', '.c-about-quotes__nav-arrow-hover-area-right', (ev) => this.onArrowHoverAreaRightMouseLeave(ev));
        $(document).on('mouseleave', '.c-about-quotes__nav-arrow-hover-area-left', (ev) => this.onArrowHoverAreaLeftMouseLeave(ev));
        $(document).on('mouseleave', '.c-about-quotes__nav-arrow-hover-area-left', (ev) => this.onArrowHoverAreaLeftMouseLeave(ev));
        $(document).on('click', '.c-about-quotes__nav-arrow-hover-area-left', (ev) => this.goPrev(ev));
        $(document).on('click', '.c-about-quotes__nav-arrow-hover-area-right', (ev) => this.goNext(ev));
        $(window).on('resize', (ev) => this.onResize(ev));
    }

    initNavLines() {
        //console.log('initNavLines');

        //const navLinesWrapperWidth = this.getQuoteNavLinesWrapper().outerWidth();
        //const lineWidth = navLinesWrapperWidth / this.defaults.countOfQuotes;

        const _this = this;

        this.getQuoteNavLine().each(function() {
           //$(this).css({'width':lineWidth+'px'});
            //console.log($(this));
           $(this).css({'width':(100 / _this.defaults.countOfQuotes)+'%'});
        });
    }

    initSlider() {
        var _this = this;
        this.activeQuoteElement = this.getActiveQuote();

        if (!this.activeQuoteElement.length) {
            return;
        }

        this.activeQuoteElement.css({
            display: "block"
        });

        this.containerOffsetLeft = this.activeQuoteElement.offset().left;
        this.containerWidth = this.activeQuoteElement.width();

        this.getQuoteNavArrowWrapper().each(function(t,n) {
            var i = this.showAnimation($(n).find(this.defaults.quoteNavArrowLeft));
            var r = this.showAnimation($(n).find(this.defaults.quoteNavArrowRight));
            i.play();
            r.play();
        }.bind(this));

        /*this.activeQuoteElement.each(function(t,n) {
            var i = this.activeQuoteElement.find(".c-about-quotes__quote-text span");
            var splittedMultilineSpans = this.splitMultilineToSeparateSpans({
                    $container: i,
                    className: "c-about-quotes__quote-line",
                    transparent: true
                });

                this.animateTextByLines(this.defaults.activeQuoteElement, this.defaults.animationDuration, this.defaults.animationDelay,
                    function(){
                        splittedMultilineSpans.cancel(false);
                        this.quoteAnimating = false;
                    }.bind(this),false,30,0, false
                )

        }.bind(this));*/

        this.activeQuoteElement.find(".c-about-quotes__quote-author").each(function(t,n){
            var a = this.activeQuoteElement.find(".c-about-quotes__quote-author");
            var i = this.showAnimation(this.activeQuoteElement.find(".c-about-quotes__quote-author p"));

            a.css({transition:"none"}).addClass("show");
            i.play();

            setTimeout((function() {
                a.css({transition:""});
            }),100);

        }.bind(this));

        $("#quote-"+this.activeQuote+" span.row").addClass("show");

        $(".c-about-quotes__quotes-wrapper").swipe({
            swipeLeft:function(t,e){
                _this.goNext()
            },
            swipeRight:function(t,e){
                _this.goPrev()
            }
        });

        $(".c-about-quotes__nav-arrows-wrapper").swipe({
            swipeLeft:function(t,e){
                _this.goNext()
            },
            swipeRight:function(t,e){
                _this.goPrev()
            }
        });
    }

    //
    getQuoteContainer() {
        return $(this.defaults.quoteContainer);
    }

    getActiveQuote() {
        return $(this.defaults.quotePrefixSelector+this.defaults.activeQuote)
    }

    getQuoteNavArrowWrapper() {
        return $(this.defaults.quoteNavArrowWrapper)
    }

    getQuoteNavLinesWrapper() {
        return $(this.defaults.quoteNavLinesWrapper)
    }

    getQuoteNavLine() {
        return $(this.defaults.quoteNavLine)
    }

    getQuoteTextElement() {
        return $(this.defaults.quoteTextElement)
    }

    getPrevQuoteNumber() {
        return 1 !== this.defaults.activeQuote ? this.defaults.activeQuote-1 : this.defaults.countOfQuotes
    }

    getNextQuoteNumber(){
        return this.defaults.activeQuote !== this.defaults.countOfQuotes ? this.defaults.activeQuote+1 : 1
    }
    //

    animateTextByLines(indexOfSlide, animationDuration, animationDelay, callback, reset, yPosition, extraDelay, reverseAnimation) {
        //console.log('animateTextByLines');

        var timeline = gsap.timeline({paused:true});
        timeline.add('start');

        var quoteElement = this.defaults.preparedSlides[indexOfSlide].$container;
        //var elementToAnimate = this.defaults.preparedSlides[indexOfSlide].$container.find(this.defaults.preparedSlides[indexOfSlide].className);
        var elementToAnimate = this.defaults.preparedSlides[indexOfSlide].$container.children();

        console.log(elementToAnimate)
        if (!yPosition) {
            yPosition = 30;
        }

        if (!extraDelay) {
            extraDelay = 0;
        }

        this.defaults.preparedSlides[indexOfSlide].animated = true;

        if (reverseAnimation) {
            timeline
                .fromTo(elementToAnimate,
                    {
                        y: 0,
                        opacity: 1,
                    },
                    {
                        y: yPosition,
                        opacity: 0,
                        ease:Expo.easeOut,
                        duration: animationDuration/1000,
                        //stagger: animationDelay/1000,
                        onComplete: () => {
                            quoteElement.css("display", "none");
                        }
                    },
                    'start'
                )
        } else {
            timeline
                .fromTo(elementToAnimate,
                    {
                        y: yPosition,
                        opacity: 0,
                    },
                    {
                        y: 0,
                        opacity: 1,
                        ease:Expo.easeOut,
                        duration: animationDuration/1000,
                        //stagger: animationDelay/1000,
                        onStart: () => {
                            quoteElement.css("display", "block");
                        }
                    },
                    'start'
                )
        }

        timeline
            .delay(extraDelay/1000)
            .add((function(){
                elementToAnimate.css({
                    //transform: "",
                    //opacity: ""
                })

                if (reset) {
                    this.defaults.preparedSlides[indexOfSlide].cancel(true)
                }

                if (callback) {
                    callback()
                }
            }))
            .play();
    }

    onArrowsWrapperMouseEnter() {
        //console.log('onArrowsWrapperMouseEnter');

        $(this.defaults.quoteNavArrowLeft).addClass("area-hover");
        $(this.defaults.quoteNavArrowRight).addClass("area-hover");
    }

    onArrowsWrapperMouseLeave() {
        //console.log('onArrowsWrapperMouseLeave');

        $(this.defaults.quoteNavArrowLeft).removeClass("area-hover");
        $(this.defaults.quoteNavArrowRight).removeClass("area-hover");
    }

    removeAllClasses() {
        //console.log('removeAllClasses');

        for(var t=0; t<this.defaults.countOfQuotes; t++){
            var e="#line"+(t+1);
            $(e).removeClass("prev-active");
            $(e).removeClass("prev-active-arrow-hover");
            $(e).removeClass("next-active");
            $(e).removeClass("next-active-arrow-hover");
            $(e).removeClass("active")
        }
    }

    setNewClasses (t){
        //console.log('setNewClasses');

        var e="#line"+this.defaults.activeQuote;
        $(e).addClass("active");

        var n = "#line"+this.getPrevQuoteNumber();
        $(n).addClass("prev-active");

        -1!==t||this.defaults.isMobile||$(n).addClass("prev-active-arrow-hover");

        var i="#line"+this.getNextQuoteNumber();
        $(i).addClass("next-active");

        1!==t||this.defaults.isMobile||$(i).addClass("next-active-arrow-hover");
    }

    setNextActiveQuote(t) {
        //console.log('setNextActiveQuote');

        this.defaults.activeQuote = -1 === t ? this.getPrevQuoteNumber() : this.getNextQuoteNumber()
    }

    onArrowHoverAreaRightMouseEnter() {
        //console.log('onArrowHoverAreaRightMouseEnter');

        $(".next-active").addClass("next-active-arrow-hover");
    }

    onArrowHoverAreaLeftMouseEnter() {
        //console.log('onArrowHoverAreaLeftMouseEnter');

        $(".prev-active").addClass("prev-active-arrow-hover")
    }

    onArrowHoverAreaRightMouseLeave() {
        //console.log('onArrowHoverAreaRightMouseLeave');

        $(".next-active").removeClass("next-active-arrow-hover")
    }

    onArrowHoverAreaLeftMouseLeave() {
        //console.log('onArrowHoverAreaLeftMouseLeave');

        $(".prev-active").removeClass("prev-active-arrow-hover")
    }

    removeQuote (indexOfSlide) {
        console.log('removeQuote: '+indexOfSlide);

        var splittedMultilineSpans = this.prepareQuote(indexOfSlide);
        this.animateTextByLines(indexOfSlide, this.defaults.animationDuration, this.defaults.animationDelay,
            null,false,30,0, true
        )
    }

    showQuote (indexOfSlide) {
        console.log('showQuote: '+indexOfSlide);

        var splittedMultilineSpans = this.prepareQuote(indexOfSlide);
        this.animateTextByLines(indexOfSlide, this.defaults.animationDuration, this.defaults.animationDelay,
            null,false,30,0, false
        )
    }

    goPrev() {
        //console.log('goPrev');
        //console.log(this.defaults.quoteAnimating);
        var _this = this;

        if(!this.defaults.quoteAnimating) {
            this.defaults.quoteAnimating = true;

            setTimeout(function(){
                this.defaults.quoteAnimating = false;
            }.bind(this),1000);

            this.removeQuote(this.defaults.activeQuote);

            var prevQuoteIndex = this.getPrevQuoteNumber();
            setTimeout((function(){
                _this.showQuote(prevQuoteIndex);
            }),10);

            this.removeAllClasses();
            this.setNextActiveQuote(-1);
            this.setNewClasses(-1);
        }
    }

    goNext() {
        //console.log('goNext');
        //console.log(this.defaults.quoteAnimating);
        var _this = this;

        if(!this.defaults.quoteAnimating){
            this.defaults.quoteAnimating = true;

            setTimeout(function(){
                this.defaults.quoteAnimating = false;
            }.bind(this),1000);

            this.removeQuote(this.defaults.activeQuote);

            var nextQuoteIndex = this.getNextQuoteNumber();
            setTimeout((function(){
                _this.showQuote(nextQuoteIndex)
            }),10);

            this.removeAllClasses();
            this.setNextActiveQuote(1);
            this.setNewClasses(1);
        }
    }

    onResize() {
        //console.log('onResize');

        if ($("#quote-"+this.defaults.activeQuote).length) {
            this.ww = $(window).width();
            this.containerOffsetLeft = $("#quote-"+this.defaults.activeQuote).offset().left;
            this.containerWidth = $("#quote-"+this.defaults.activeQuote).width();
        }
    }

    showAnimation(element) {
        //console.log('showAnimation');

        element.css({
            "will-change":"opacity, transform"
        });

        var tl = gsap.timeline({paused: true});

        tl.staggerFromTo(element.toArray(), this.defaults.animationDuration/1000, {
            y:30,
            opacity:.01
        },{
            y:0,
            opacity:1,
            ease:Expo.easeOut
        }, this.defaults.animationDelay/1000, 0);


        tl.add((function(){
            element.css({
                transform: "",
                opacity: ""
            })
        }));

        return tl;
    }

    prepareQuote(indexQuote) {
        //console.log('prepareQuote');

        var i = $("#quote-"+indexQuote);

        if (!this.defaults.preparedSlides[indexQuote]) {
            this.defaults.preparedSlides[indexQuote] = {
                $container: i,
                className: ".c-about-quotes__quote-text, c-about-quotes__quote-author",
                transparent: true
            };
        }

        return this.defaults.preparedSlides[indexQuote];
    }
}