import SlimSelect from 'slim-select';

export default class Forms {

    constructor(options) {
        const _defaults = {
            form: '.js-form',
            formInput: '.js-form-input',
            formFieldset: '.js-form-fieldset',
            formTextarea: '.js-form-textarea',
            formSelect: '.js-form-select',
            formSubmit: '.js-form-submit',
            formReset: '.js-form-reset',
            formContainer: '.js-form-container',
            formThankYouContainer: '.js-form-thank-you',
            formThankYouContainerItem: '.js-form-thank-you-item',
            formLoader: '.ajax-loader',
            formSubmitLoader: '.js-form-loader',
            formSubmitLoaderLine: '.js-form-loader-line',
            formAgree: '.js-form-agree-checkbox',
            formIsCF7: false,
            formInitSlimSelect: false,
        };

        this.defaults = Object.assign({}, _defaults, options);
    }

    // endregion

    // region Getters

    getForm() {
        return $(this.defaults.form);
    }

    getAjaxLoader() {
        return $(this.defaults.formLoader);
    }

    getFormFieldset() {
        return $(this.defaults.formFieldset);
    }

    getFormInput() {
        return $(this.defaults.formInput);
    }

    getFormContainer() {
        return $(this.defaults.formContainer);
    }

    getFormSelect() {
        return $(this.defaults.formSelect);
    }

    getFormTextarea() {
        return $(this.defaults.formTextarea);
    }

    getFormSubmit() {
        return $(this.defaults.formSubmit);
    }

    getFormAgree() {
        return $(this.defaults.formAgree);
    }

    getFormReset() {
        return $(this.defaults.formReset);
    }

    getFormFileUpload() {
        return $(this.defaults.formFileUpload);
    }

    getCareersCvUpload() {
        return $(this.defaults.careersCvUpload);
    }

    getCareersMlUpload() {
        return $(this.defaults.careersMotivationalLetterUpload);
    }

    getCareersOmUpload() {
        return $(this.defaults.careersOtherMaterialUpload);
    }

    getThankYouContainer() {
        return $(this.defaults.formThankYouContainer);
    }

    getThankYouContainerItem() {
        return $(this.defaults.formThankYouContainerItem);
    }

    getFormSubmitLoader() {
        return $(this.defaults.formSubmitLoader);
    }

    getFormSubmitLoaderLine() {
        return $(this.defaults.formSubmitLoaderLine);
    }

    // endregion

    // region Methods
    init() {
        //console.log('form init')

        if (this.getForm().length > 0) {
            this.textareaResize();
            this.initFloatingLabels();
            this.focusFields();
            this.blurFields();
            this.resetForm();
            this.initEnableFormSubmit();
            this.contactFormEvents();
        }

        // INIT CUSTOM SELECT - SLIM SELECT
        if (this.getFormSelect().length > 0 && this.defaults.formInitSlimSelect) {
            $(this.defaults.formSelect).each((index, element) => {
                this.initSlimSelect(element);
            });
        }
    }

    textareaResize() {
        //console.log('form textareaResize')

        this.getFormTextarea().each((ev) => {
            $(ev.currentTarget).attr('style', `height: ${$(ev.currentTarget).scrollHeight}px; overflow-y: hidden;`);
        }).on('input', (ev) => {
            ev.currentTarget.style.height = 'auto';
            ev.currentTarget.style.height = `${ev.currentTarget.scrollHeight}px`;
        });
    }

    initFloatingLabels() {
        //console.log('form initFloatingLabels')

        this.getFormInput().each((index, element) => {
            //console.log($(element))
            this.floatingLabels($(element));
        });

        this.getFormInput().on('input change', (ev) => {
            //console.log($(ev.currentTarget))

            this.floatingLabels($(ev.currentTarget));
        });
    }

    floatingLabels($currentTarget) {
        const fieldId = $currentTarget.attr('id');
        const fieldset = $('label[for="'+fieldId+'"]').closest(this.defaults.formFieldset)
        //const fieldset = $currentTarget.closest(this.defaults.formFieldset);

        fieldset.each(function(){
            const floatOnDesktop = $(this).data('float-on-desktop');
            if ($('html').hasClass('is-mobile') || ($('html').hasClass('is-desktop') && floatOnDesktop !== 'no')) {
                if ($currentTarget.val()) {
                    $(this).addClass('is-filled');
                } else {
                    $(this).removeClass('is-filled');
                }
            }
        })
    }

    focusFields() {
        //console.log('form focusFields')

        this.getFormInput().on('focus', (ev) => {
            const fieldset = $(ev.currentTarget).closest(this.defaults.formFieldset);

            fieldset.addClass('is-focused');
        });
    }

    blurFields() {
        //console.log('form blurFields')

        this.getFormInput().on('blur', (ev) => {
            const fieldset = $(ev.currentTarget).closest(this.defaults.formFieldset);

            fieldset.removeClass('is-focused');
        });
    }

    resetForm() {
        //console.log('form resetForm')

        this.getFormReset().on('click', (ev) => {
            const currentForm = $(ev.currentTarget).closest(this.defaults.form);

            currentForm.find(this.defaults.formFieldset).removeClass('is-filled');
            //currentForm.find(this.defaults.formSubmit).attr('disabled', true);
            currentForm.find(this.defaults.formTextarea).attr('style', `height: auto`);
            currentForm.find(`${this.defaults.formSelect} option:selected`).prop('selected', false);
            currentForm.find('.placeholder').html('&nbsp;');
        });
    }

    initEnableFormSubmit() {
        //console.log('form initEnableFormSubmit')

        this.getFormAgree().each((index, element) => {
            this.enableFormSubmit($(element));
        });

        this.getFormAgree().on('change', (ev) => {
            this.enableFormSubmit($(ev.currentTarget));
        });
    }

    enableFormSubmit($currentTarget) {
        const currentForm = $currentTarget.closest(this.defaults.form);

        if ($currentTarget.prop('checked')) {
            currentForm.find(this.defaults.formSubmit).removeAttr('disabled');
        } else if ($currentTarget.prop('checked', false)) {
            currentForm.find(this.defaults.formSubmit).attr('disabled', true);
        }
    }

    contactFormEvents() {
        //console.log('form contactFormEvents')

    }

    initSlimSelect(element) {
        const slimSelect = new SlimSelect({
            select: element,
            showSearch: false,
            showContent: 'down',
            placeholder: '&nbsp;',
        });

        this.fixSlimSelectDataAttribute(element, slimSelect);
    }

    fixSlimSelectDataAttribute(element, slimSelect) {
        $.each($(slimSelect.select.element).data(), function (name, value) {
            $(slimSelect.slim.container).attr('data-'+name, value);
        })
    }
}
