import Ajax from "./Ajax";
import Window from '../utils/Window';
import Register from '../utils/Register';

import CustomizeBundle from '../validation/customizeBundle';
import '../validation';
import showFormErrorsWindowOnFrontend from '../utils/showFormErrorsWindow';
import '../../../../vendor/fp/jsformvalidator-bundle/src/Resources/public/js/FpJsFormValidatorWithJqueryInit.js'
CustomizeBundle.showFormErrorsWindow = showFormErrorsWindowOnFrontend;

export default class ContactForm {

    constructor(options) {
        const _defaults = {
            form: '.js-form-ajax',
        };

        this.defaults = Object.assign({}, _defaults, options);
    }

    static onReCaptchaSuccess() {
        //console.log('onReCaptchaSuccess')
    }

    static onReCaptchaError() {
        //console.log('onReCaptchaError')
    }

    static onReCaptchaExpired() {
        //console.log('onReCaptchaExpired')
    }

    getForm() {
        return $(this.defaults.form);
    }

    init() {
        let _this = this;

        _this.getForm().each(function(){
            let id = $(this).data('id');
            let methodName = 'jsFpInitModelValidators_'+id;
            // init validatior model of jsFpValidator
            eval(window[methodName]);
            $(this).bind('contactFormAjaxSubmit', (e) => _this.ajaxSubmit(e, _this));
        });
    }

    ajaxSubmit(event, _this) {
        let $form = $(event.target);
        Ajax.ajax({
            //loaderElement: _this.getForm().closest('.js-contact-form-container'),
            url: $form.attr('action'),
            method: 'post',
            data: $form.serialize(),
            dataType: 'json',
            success: (data) => {
                _this.showWindow(data);
                _this.resetForm($form);
                _this.sendConversion($form);
            }
        });
        event.preventDefault();
    };

    sendConversion($form) {
        // DOC
        // code in GA: ga('send', 'event', 'Videos', 'play', 'Fall Campaign');
        // same code in GTAG: gtag('event', 'play', { 'event_category': 'Videos', 'event_label': 'Fall Campaign' });

        //ga('send', 'event', 'Contact Form - Services', 'Submit', 'Send');
        gtag('event', 'Submit', {
            'event_category': 'Contact Form - Services',
            'event_label': 'Send'
        })
        fbq('track', 'Lead', {content_name: 'Contact Form - Services'});
        // dataLayer.push({
        //     'event': '_d_GAEvent',
        //     'eventCategory': 'Contact Form',
        //     'eventAction': 'Success',
        //     'eventLabel': window.location.href,
        // });
    }

    showWindow(data) {
        var $container = $('#js-contact-form-container');

        $container.html(data['contactFormHtml']);
        (new Register).registerNewContent($container);

        new Window({
           content: data['message']
        });
    }

    resetForm($form) {
        $form.trigger('reset');
        $form.find('button[type="submit"]').attr('disabled', true);
    }
}
