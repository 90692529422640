/* eslint-disable */

const constant = {
    '\\FOS\\CKEditorBundle\\Form\\Type\\CKEditorType::class': 'FOS\\CKEditorBundle\\Form\\Type\\CKEditorType',
    '\\EWZ\\Bundle\\RecaptchaBundle\\Form\\Type\\EWZRecaptchaType::class': 'EWZ\\Bundle\\RecaptchaBundle\\Form\\Type\\EWZRecaptchaType',
    '\\Symfony\\Component\\Form\\Extension\\Core\\Type\\CheckboxType::class': 'Symfony\\Component\\Form\\Extension\\Core\\Type\\CheckboxType',
    '\\Symfony\\Component\\Form\\Extension\\Core\\Type\\ChoiceType::class': 'Symfony\\Component\\Form\\Extension\\Core\\Type\\ChoiceType',
    '\\Symfony\\Component\\Form\\Extension\\Core\\Type\\CollectionType::class': 'Symfony\\Component\\Form\\Extension\\Core\\Type\\CollectionType',
    '\\Symfony\\Component\\Validator\\Constraint::DEFAULT_GROUP': 'Default',
};

export default (key) => constant[key];
