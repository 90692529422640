import Barba from 'barba.js';

// load gsap and components
import { gsap, TweenMax, TimelineMax } from "gsap";

// load and register scroll component
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

// load and init scroll magic component
import ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

// load customized FpJsValidator
// import CustomizeBundle from '../validation/customizeBundle';
// import '../validation';
// import showFormErrorsWindowOnFrontend from '../utils/showFormErrorsWindow';
// import '../../../../vendor/fp/jsformvalidator-bundle/src/Resources/public/js/FpJsFormValidatorWithJqueryInit.js'
// CustomizeBundle.showFormErrorsWindow = showFormErrorsWindowOnFrontend;

// load barba transitions
import { fadeOut, fadeIn, scrollTop } from './barba/transition/fadeTransition'
import { workFadeOut, workFadeIn, workScrollTop } from './barba/transition/workTransition'
import { nextCaseScrollCaseToTop, nextCaseFadeInNextCase } from './barba/transition/nextCaseTransition'
import { prevCaseScrollCaseToTop, prevCaseFadeInNextCase } from './barba/transition/prevCaseTransition'
import { featuredWorkFadeOut, featuredWorkFadeIn, featuredWorkScrollTop } from './barba/transition/featuredWorkTransition'

import CookiesPlugin from "./CookiesPlugin";

// load barba definition for pages
import initBarbaHomepage from "./barba/page/homepage";
import initBarbaAbout from "./barba/page/about";
import initBarbaService from "./barba/page/service";
import initBarbaWork from "./barba/page/work";
import initBarbaWorkSingle from "./barba/page/workSingle";
import initBarbaContact from "./barba/page/contact";
import initBarbaErrorPage from "./barba/page/errorpage";

// init barba definition for pages
new initBarbaHomepage();
new initBarbaErrorPage();
new initBarbaAbout();
new initBarbaService();
new initBarbaWork();
new initBarbaWorkSingle();
new initBarbaContact();

// load and init navigation
import Navigation from "./Navigation";
const navigation = new Navigation();

// def global property
let currentAnchor = '';

export default class BarbaHandler {
    constructor() {
        this.initBarba();
        this.linkClicked = false;
    }

    initBarba() {
        Barba.Utils.xhrTimeout = 40000;
        Barba.Pjax.init();
        Barba.Prefetch.init();

        // Google analytics pageviews
        Barba.Dispatcher.on('initStateChange', () => {
            this.linkClicked = false;

            if($('body').hasClass('is-menu-opened')) {
                navigation.closeMenu();
            }
        });

        // on link clicked
        Barba.Dispatcher.on('linkClicked', (anchor) => {
            currentAnchor = anchor;
            this.linkClicked = true;

            // use page transition by link type
            if ($(anchor).hasClass('js-next-case')) {
                Barba.Pjax.getTransition = () => this.nextCaseTransition();
            } else if ($(anchor).hasClass('js-prev-case')) {
                Barba.Pjax.getTransition = () => this.prevCaseTransition();
            } else if ($(anchor).hasClass('js-work-link') && $('html').hasClass('is-desktop')) {
                Barba.Pjax.getTransition = () => this.workTransition();
            } else if ($(anchor).hasClass('js-featured-work-link') && $('html').hasClass('is-desktop')) {
                Barba.Pjax.getTransition = () => this.featuredWorkTransition();
            } else {
                Barba.Pjax.getTransition = () => this.fadeTransition();
            }
        });

        // on transition completed
        Barba.Dispatcher.on('transitionCompleted', () => {});

        // on new page transition completed and page is ready
        Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, container, newPageRawHTML) {

            // update body classes and language attribute
            var response = newPageRawHTML.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', newPageRawHTML);
            var bodyClasses = $(response).filter('notbody').attr('class');
            var bodyLang = $(response).filter('notbody').attr('lang');
            $('body').attr('class', bodyClasses);
            $('body').attr('lang', bodyLang);

            // eval inline scripts
            var js = container.querySelectorAll('script');
            [].forEach.call(js, function(script) {
                eval(script.innerHTML);
            });

            new CookiesPlugin();

            // $.ajax({}).done(function (data) {
            //     $(container).find('script').each(function (index, element) {
            //         console.log(element.innerHTML)
            //         eval(element.innerHTML);
            //     })
            // });
        })
    }

    // general transition
    fadeTransition() {
        return Barba.BaseTransition.extend({
            start() {
                const promises = [
                    this.newContainerLoading,
                    fadeOut(this),
                ];
                // As soon the loading is finished and the old page is faded out, let's fade the new page
                Promise
                    .all(promises)
                    .then(values => scrollTop(this))
                    .then(value => fadeIn(this));
            }
        });
    }

    // transition for previous case study
    prevCaseTransition() {

        if (!this.linkClicked) {
            return this.fadeTransition();
        }

        return Barba.BaseTransition.extend({
            start() {
                const promises = [
                    this.newContainerLoading,
                    prevCaseScrollCaseToTop(this),
                ];
                // As soon the loading is finished and the old page is faded out, let's fade the new page
                Promise
                    .all(promises)
                    .then(values => prevCaseFadeInNextCase(this));
            },
        });
    }

    // transition for next case study
    nextCaseTransition() {

        if (!this.linkClicked) {
            return this.fadeTransition();
        }

        return Barba.BaseTransition.extend({
            start() {
                const promises = [
                    this.newContainerLoading,
                    nextCaseScrollCaseToTop(this)
                ];
                // As soon the loading is finished and the old page is faded out, let's fade the new page
                Promise
                    .all(promises)
                    .then(values => nextCaseFadeInNextCase(this));
            },
        });
    }

    // transition for case study in work list
    workTransition() {

        if (!this.linkClicked) {
            return this.fadeTransition();
        }

        return Barba.BaseTransition.extend({

            start() {
                const promises = [
                    this.newContainerLoading,
                    workFadeOut(this, currentAnchor),
                ];
                // As soon the loading is finished and the old page is faded out, let's fade the new page
                Promise
                    .all(promises)
                    .then(values => workScrollTop(this))
                    .then(value => workFadeIn(this));
            }
        });
    }

    // transition for case study in featured work list
    featuredWorkTransition() {

        if (!this.linkClicked) {
            return this.fadeTransition();
        }

        return Barba.BaseTransition.extend({

            start() {
                const promises = [
                    this.newContainerLoading,
                    featuredWorkFadeOut(this, currentAnchor),
                ];
                // As soon the loading is finished and the old page is faded out, let's fade the new page
                Promise
                    .all(promises)
                    .then(values => featuredWorkScrollTop(this))
                    .then(value =>featuredWorkFadeIn(this));
            }
        });
    }
}
