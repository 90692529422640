import { gsap } from 'gsap'
import ScrollMagic from "scrollmagic";
//import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
//ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export default class OnScrollAnimations {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        this.onScrollControllers = {};
        this.onScrollScenes = {};

        this.defaults = {
            animationEl: '[data-animation-set]',
            animation: '[data-animation]',
        };
    }

    // region getters
    getAnimationEl() {
        return $(this.configuration.animationEl);
    }

    getAnimation() {
        return $(this.configuration.animation);
    }
    // endregion

    // region Methods

    attach(options = {}) {
        this.configuration = Object.assign({}, this.defaults, options);

        if (this.getAnimationEl().length > 0) {
            this.init();
        }
    }

    destroy() {
        if (!this.configuration) {
            return;
        }

        this.getAnimationEl().each((index, elem) => {
            if (this.onScrollControllers[index]) {
                this.onScrollControllers[index].destroy();
                this.onScrollControllers[index] = null;
            }
            if (this.onScrollScenes[index]) {
                this.onScrollScenes[index].destroy();
                this.onScrollScenes[index] = null;
            }
        });
    }

    init() {
        // console.log('OnScrollAnimations init()');

        // SET ANIMATION
        this.getAnimationEl().each((index, elem) => {
            const $el = $(elem);

            const animationOption = $el.data('animation-set');

            gsap.set($el, animationOption);
        });

        // ANIMATE
        this.getAnimation().each((index, elem) => {
            const $el = $(elem);

            const animationOptions = $el.data('animation');
            let animationDuration = $el.data('animation-duration');
            let animationTrigger = $el.data('animation-trigger');
            let animationReverse = $el.data('animation-reverse');

            if (animationDuration === undefined) {
                animationDuration = 0.6;
            }

            if (animationTrigger === undefined) {
                animationTrigger = 0.9;
            }

            if (animationReverse === undefined) {
                animationReverse = false;
            }

            this.onScrollControllers[index] = new ScrollMagic.Controller();

            const tween = gsap.timeline();

            tween.to(elem, animationDuration, animationOptions);

            // build scene
            this.onScrollScenes[index] = new ScrollMagic.Scene({
                triggerElement: elem,
                triggerHook: animationTrigger,
            })
                .setTween(tween)
                // .addIndicators() // add indicators (requires plugin)
                .addTo(this.onScrollControllers[index]);
            this.onScrollScenes[index].reverse(animationReverse);
            // this.onScrollScenes[index].reverse(true);
        });
    }

    // endregion
}
