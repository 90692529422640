//import './vendor/uiMorphButton_fixed'
import { gsap, Power3, Power4 } from 'gsap'
import { KeyCodes } from "../utils/KeyCodes";

let currentMorphTrigger = '';

export default class MorphButton {

    constructor(options) {
        const _defaults = {
            morphTrigger: '.js-morph-trigger',
            morphButtonTrigger: 'button.js-morph-trigger',
            morphContainer: '.js-morph-container',
            morphModal: '.js-morph-modal',
            morphModalInner: '.js-morph-modal-inner',
            morphOverlay: '.js-morph-overlay',
            morphTransition: '.js-morph-transition',
            morphStageOut: '.js-morph-stage-out',
            morph: '.js-morph',
            menuStage: '.js-menu-stage',
        };

        this.defaults = Object.assign({}, _defaults, options);

        this.trigger = null;

        this.init();
    }

    getMenuStage() {
        return $(this.defaults.menuStage);
    }

    getMorphTrigger() {
        return $(this.defaults.morphTrigger);
    }

    getMorphContainer() {
        return $(this.defaults.morphContainer);
    }

    getMorphButtonTrigger() {
        return $(this.defaults.morphButtonTrigger);
    }

    getMorphModal() {
        return $(this.defaults.morphModal);
    }

    getMorphModalInner() {
        return $(this.defaults.morphModalInner);
    }

    getMorphOverlay() {
        return $(this.defaults.morphOverlay);
    }
    
    getMorphTransition() {
        return $(this.defaults.morphTransition);
    }

    getMorphStageOut() {
        return $(this.defaults.morphStageOut);
    }

    getMorph() {
        return $(this.defaults.morph);
    }

    init() {
        this.initClicks();
        this.initEscButton();
    }

    initClicks() {
        this.getMorphTrigger().off('click');
        this.getMorphTrigger().on('click', (ev) => this.toggleMorph(ev));
    }

    toggleMorph(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        //currentMorphTrigger = this.fixClickedTriggerElement(ev.target);
        //currentMorphTrigger = currentMorphTrigger ? currentMorphTrigger : ev.target;

        $('body').hasClass('is-morph-opened') ? this.close() : this.open();
    }

    fixClickedTriggerElement(clickedTarget) {
        if ($(clickedTarget).hasClass('js-morph-trigger')) {
            return $(clickedTarget);
        } else {
            this.fixClickedTriggerElement($(clickedTarget).closest('js-morph-trigger'));
        }
    }

    initEscButton() {
        const _this = this;
        $(document).keyup((ev) => {
            if ((ev.keyCode === KeyCodes.ESCAPE) && (_this.getMorphModal().hasClass('is-opened'))) {
                _this.close();
            }
        });
    }

    getPosition(elem, target) {
        var targetRect = target[0].getBoundingClientRect();
        var elemRect = elem[0].getBoundingClientRect();

        gsap.set(elem, {
            x: 0,
            y: 0,
            width: targetRect.width,
            height: targetRect.height
        });

        var newRect = elem[0].getBoundingClientRect();

        gsap.set(elem, { width: elemRect.width, height: elemRect.height });

        return {
            left: targetRect.left - newRect.left,
            top: targetRect.top - newRect.top,
            width: newRect.width,
            height: newRect.height
        };
    }

    calculatePosition(element) {

        var rect = element[0].getBoundingClientRect();

        var scrollTop  = window.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop  || 0;
        var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0;

        var clientTop  = document.documentElement.clientTop  || document.body.clientTop  || 0;
        var clientLeft = document.documentElement.clientLeft || document.body.clientLeft || 0;

        return {
            top: Math.round(rect.top + scrollTop - clientTop),
            left: Math.round(rect.left + scrollLeft - clientLeft),
            height: rect.height,
            width: rect.width,
        };
    }

    open() {
        //console.log('open morph');

        $('body').addClass('is-morph-opened');

        var fromHero = this.getMorphButtonTrigger();
        var toHero = this.getMorphTransition();

        //var clone = fromHero[0].cloneNode();
        const clone = document.createElement('div');

        var from = this.calculatePosition(fromHero);
        var to = this.calculatePosition(toHero);

        // start from subline of bordered button
        from.height = 3;
        from.top = this.getMorphTrigger().offset().top + this.getMorphTrigger().outerHeight() - 15;

        const cloneParams = {
            position: "absolute",
            margin: 0,
            borderRadius: 4,
            backgroundColor: this.getMorphTransition().data('color-bg'),
            zIndex: 20,
        };

        const cloneFromParameters = {...from, ...cloneParams }

        //gsap.set([fromHero, toHero], { visibility: "hidden" });
        gsap.set([toHero], { visibility: "hidden" });
        gsap.set(clone, cloneFromParameters);

        gsap.set(this.getMorphModal(), {
            autoAlpha: 0,
            y: '5%',
            skewY: '1.5deg',
        });

        document.body.appendChild(clone);

        gsap.to(clone, {
            duration: 0.5,
            y: to.top - from.top,
            height: to.height,
            autoRound: false,
            ease: Power4.easeOut,
            onStart: () => {
                this.getMorphModal().addClass('is-opening');
                this.getMorph().addClass('is-opening');

                gsap.to(this.getMenuStage(), {
                    duration: 0.3,
                    stagger: 0.2,
                    autoAlpha: 0,
                    ease: Power3.easeNone,
                });
            },
            onComplete: () => {
                gsap.to(clone, {
                    duration: 0.2,
                    x: to.left - from.left,
                    width: to.width,
                    autoRound: false,
                    ease: Power4.easeOut,
                    onComplete: () => {
                        gsap.set(toHero, { visibility: "visible" });
                        document.body.removeChild(clone);

                        gsap.to(this.getMorphModal(), {
                            duration: 0.3,
                            autoAlpha: 1,
                            y: '0%',
                            skewY: '0deg',
                            ease: Power3.easeNone,
                        })

                        this.getMorphContainer().addClass('is-morph-opened');
                        this.getMorphModal().removeClass('is-opening');
                        this.getMorph().removeClass('is-opening');
                        this.getMorphModal().addClass('is-opened');
                        this.getMorph().addClass('is-opened');
                    }
                })
            }
        })
    }

    close() {
        //console.log('close morph');

        var fromHero = this.getMorphTransition();
        var toHero = this.getMorphTrigger();

        var clone = fromHero[0].cloneNode();
        clone = document.createElement('div');
        var from = this.calculatePosition(fromHero);
        var to = this.calculatePosition(toHero);

        // final to subline of bordered button
        to.height = 3;
        to.top = this.getMorphTrigger().offset().top + this.getMorphTrigger().outerHeight() - 3;

        //gsap.set([fromHero, toHero], { visibility: "hidden" });
        gsap.set([fromHero], { visibility: "hidden" });
        gsap.set(clone, { position: "absolute", margin: 0, borderRadius: 0 });

        document.body.appendChild(clone);

        gsap.set(clone, from);
        gsap.set(clone, {
            backgroundColor: this.getMorphTransition().data('color-bg'),
            zIndex: 20,
        });

        gsap.set(this.getMorphModal(), {
            autoAlpha: 1,
            y: '0%',
            skewY: '0deg',
        })

        gsap.to(this.getMorphModal(), {
            duration: 0.3,
            autoAlpha: 0,
            y: '5%',
            skewY: '1.5deg',
            ease: Power3.easeNone,
            onStart: () => {
                this.getMorphContainer().removeClass('is-morph-opened');
            },
            onComplete: () => {
                gsap.to(clone, {
                    duration: 0.5,
                    x: to.left - from.left,
                    width: to.width,
                    autoRound: false,
                    ease: Power4.easeOut,
                    onStart: () => {
                        this.getMorphModal().addClass('is-closing');
                        this.getMorph().addClass('is-closing');

                        gsap.to(this.getMenuStage(), {
                            duration: 0.3,
                            stagger: 0.05,
                            autoAlpha: 1,
                            ease: Power3.easeNone,
                        });
                    },
                    onComplete: () => {
                        gsap.to(clone, {
                            duration: 0.2,
                            y: to.top - from.top,
                            height: to.height,
                            borderRadius: 4,
                            autoRound: false,
                            ease: Power4.easeOut,
                            onComplete: () => {
                                gsap.set(toHero, { visibility: "visible" });
                                document.body.removeChild(clone);

                                gsap.to(this.getMenuStage(), {
                                    duration: 0.3,
                                    stagger: 0.2,
                                    autoAlpha: 1,
                                    ease: Power3.easeNone,
                                });

                                $('body').removeClass('is-morph-opened');
                                this.getMorphModal().removeClass('is-opened');
                                this.getMorph().removeClass('is-opened');
                            }
                        })
                    }
                })
            }
        });
    }




































/*
    openModal4(e) {
        var newRect = this.getPosition(this.getMorphModal(), $(e.target));
        const morphTrigger = this.getMorphTrigger();

        let initialWidth = 0;
        let initialHeight = 0;
        let initialTop = 0;
        let initialLeft = 0;

        if (this.getMorphTransition().length > 0) {
            initialWidth = morphTrigger.outerWidth();
            initialHeight = morphTrigger.outerHeight();
            initialTop = morphTrigger.offset().top;
            initialLeft = morphTrigger.offset().left;

            initialWidth = newRect.width;
            initialHeight = newRect.height;
            initialTop = newRect.top;
            initialLeft = newRect.left;
        }

        const workAnimationTl = new TimelineMax({
            onStart: () => {

            },
            onComplete: () => {
                currentMorphTrigger = '';
            },
        });

        const initColor = $(currentMorphTrigger).data('work-color') || '#ffffff';

        workAnimationTl.add('start')
            .to(this.getMorphStageOut(), 0.2, {
                autoAlpha: 0,
                onStart: () => {
                    //gsap.set(this.getMorphTransition(), {
                    //    backgroundColor: initColor,
                    //});
                    gsap.set(this.getMorphTransition(), {
                        top: 0, // 0
                        left: initialLeft,
                        width: initialWidth,
                        height: initialHeight,
                        autoAlpha: 1,
                        backgroundColor: initColor,
                    });
                },
                onComplete: () => {

                },
            }, 'start')
            .to(this.getMorphTransition(), 0.6, {
                left: 0,
                width: "100vw",
                ease: Expo.easeOut,
            })
            .to(this.getMorphTransition(), 0.6, {
                top: 0,
                height: "100vh",
                ease: Expo.easeOut,
                onComplete: () => {
                    gsap.set(this.getMorphModal(), {
                        x: '50%',
                        y: '50%',
                        width: 800,
                        height: 400,
                        autoAlpha: 1
                    });

                    $('body').addClass('is-morph-opened');
                    this.getMorphTrigger().removeClass('is-disabled');
                    this.getMorphTrigger().addClass('is-opened');
                    this.getMorphModal().addClass('is-opened');
                    this.getMorph().addClass('is-opened');
                },
            })
        ;
    }

    openModal3(e) {

        const openMorphModal = new TimelineMax({
            onStart: () => {
                this.getMorphTrigger().addClass('is-disabled');
                this.getMorph().addClass('is-opening');

                var newRect = this.getPosition(this.getMorphModal(), $(e.target));
                this.trigger = $(e.target);

                gsap.set([this.getMorphStageOut(), this.getMorphOverlay(), this.getMorphModal()], {
                    autoAlpha: 0
                });

                //gsap.set([this.this.getMorphModalInner()], {
                //    autoAlpha: 0
                //});

                gsap.set(this.getMorphModal(), {
                    x: newRect.left,
                    y: newRect.top,
                    width: newRect.width,
                    height: newRect.height
                });
            },
            onComplete: () => {
                //gsap.set([this.getMorphModalInner()], {
                //    autoAlpha: 1
                //});

                this.getMorph().removeClass('is-opening');

                $('body').addClass('is-morph-opened');
                this.getMorphTrigger().removeClass('is-disabled');
                this.getMorphTrigger().addClass('is-opened');
                this.getMorphModal().addClass('is-opened');
                this.getMorph().addClass('is-opened');
            },
        });

        openMorphModal.add('start')
            .to(this.getMorphOverlay(), 0.5, {
                autoAlpha: 0.75
            },'start')
            .to(this.getMorphModal(), 0.5, {
                x: '50%',
                y: '50%',
                width: 800,
                height: 400,
                autoAlpha: 1
            },'start');
    }

    closeModal3() {
        var newRect = this.getPosition(this.getMorphModal(), this.trigger);

        const closeMorphModal = new TimelineMax({
            onStart: () => {
                this.getMorph().addClass('is-closing');

                //gsap.set([this.getMorphModalInner()], {
                //    autoAlpha: 0
                //});
            },
            onComplete: () => {
                gsap.set([this.getMorphStageOut()], {
                    autoAlpha: 1
                });

                this.getMorph().removeClass('is-closing');

                $('body').removeClass('is-morph-opened');
                this.getMorphTrigger().addClass('is-disabled');
                this.getMorphTrigger().removeClass('is-opened');
                this.getMorphModal().removeClass('is-opened');
                this.getMorph().removeClass('is-opened');

                this.getMorphTrigger().removeClass('is-disabled');
            }
        });

        //gsap.killTweensOf(".messageWrapper");

        closeMorphModal.to(this.getMorphModal(), 0.5, {
            autoAlpha: 1,
            x: newRect.left,
            y: newRect.top,
            height: newRect.height,
            width: newRect.width
        });
        closeMorphModal.to(this.getMorphOverlay(), 0.5, {
            autoAlpha: 0
        });
    }

    openModal2(e) {

        gsap.set([this.getMorphOverlay(), this.getMorphModal()], { autoAlpha: 0 });

        var newRect = this.getPosition(this.getMorphModal(), $(e.target));
        this.trigger = $(e.target);

        gsap.set(this.getMorphModal(), {
            x: newRect.left,
            y: newRect.top,
            width: newRect.width,
            height: newRect.height
        });

        var show = new TimelineMax();

        show.to(this.getMorphOverlay(), 0.5, { autoAlpha: 0.75 });
        show.to(this.getMorphModal(), 0.5, {
            x: 0,
            y: 0,
            width: 800,
            height: 400,
            autoAlpha: 1
        });
    }

    openModal() {
        this.trigger = this.getMorphTrigger();

        gsap.set(this.getMorphModal(), {
            width: this.trigger.outerWidth(),
            height: this.trigger.outerHeight(),
            left: this.trigger.position().left,
            top: this.trigger.position().top,
        });

        const openMorphModal = new TimelineMax({
            onStart: () => {
                this.getMorphTrigger().addClass('is-disabled');
            },
            onComplete: () => {
                $('body').addClass('is-morph-opened');
                this.getMorphTrigger().removeClass('is-disabled');
                this.getMorphTrigger().addClass('is-opened');
                this.getMorphModal().addClass('is-opened');
                this.getMorph().addClass('is-opened');
            },
        });

        openMorphModal.add('start')
            //.to(this.getMorphStageOut(), 0.2, {
            //    autoAlpha: 0,
            //},'start', '-=0.5')
            //.to(this.getMorphModal(), 0.2, {
            //    autoAlpha: 1,
            //},'start', '-=0.5')
            .fromTo(this.getMorphModal(), 0.2, {
                width: this.trigger.outerWidth(),
                height: this.trigger.outerHeight(),
                left: this.trigger.position().left,
                top: this.trigger.position().top,
            },{
                autoAlpha: 1,
                xPercent: -50,
                yPercent: -50,
                width: '80vw',
                height: '80vh',
                zIndex: 100,
                top: '50%',
                left: '50%',
            },'start', '+=0.1')
    }

    closeModal() {

        const closeMorphModal = new TimelineMax({
            onStart: () => {

            },
            onComplete: () => {
                $('body').removeClass('is-morph-opened');
                this.getMorphTrigger().addClass('is-disabled');
                this.getMorphTrigger().removeClass('is-opened');
                this.getMorphModal().removeClass('is-opened');
                this.getMorph().removeClass('is-opened');

                this.getMorphTrigger().removeClass('is-disabled');
            }
        });

        closeMorphModal.add('start')
            .to(this.getMorphStageOut(), 0.2, {
                autoAlpha: 1,
            },'start', '-=0.5')
            .to(this.getMorphModal(), 0.2, {
                autoAlpha: 0,
            },'start', '-=0.5')
            .to(this.getMorphModal(), 0.2, {
                autoAlpha: 0,
                width: this.trigger.outerWidth(),
                height: this.trigger.outerHeight(),
                left: this.trigger.position().left,
                top: this.trigger.position().top,
            },'start', '+=0.1')
    }
 */
}