import $ from 'jquery';
import is from 'is_js';

// extend jquery
import registerFilterAllNodes from './utils/registerFilterAllNodes.js';
import registerEaseOutExpo from './utils/registerEaseOutExpo.js';

// load common components
import BarbaHandler from './components/BarbaHandler';
import Loader from "./components/Loader";
import Cursor from "./components/Cursor";
import CustomSelect from "./components/CustomSelect";
import CookiesPlugin from "./components/CookiesPlugin";
// import Translator from 'bazinga-translator';

$(document).ready(() => {

    // is explorer
    if (is.ie()) {
        $('html').addClass('is-ie');
    }

    // is firefox
    if (is.firefox()) {
        $('html').addClass('is-firefox');
    }

    // set desktop or mobile device
    if (is.desktop() && window.screen.width > 1024) {
        $('html').addClass('is-desktop');
    } else {
        $('html').addClass('is-mobile');
    }

    // hide honey fields
    $('.js-honey').hide();

    // init loader
    new Loader();

    // init barba transitions & pages
    new BarbaHandler();

    // init cursor
    (new Cursor()).init();

    // init custom select
    (new CustomSelect()).init();

    // cookie consent
    new CookiesPlugin();

    // window.Translator = Translator;
});

registerFilterAllNodes();
registerEaseOutExpo();
