import ImagesAnimation from "../../ImagesAnimation";
import ExitEnter from "../../ExitEnter";
import Navigation from "../../Navigation";
import Parallax from "../../Parallax";
import OnScrollAnimations from "../../OnScrollAnimations";
import LazyLoad from "vanilla-lazyload";
import ClipNavigation from "../../ClipNavigation";
//import Cursor from "../../Cursor";
import MorphingButton from "../../MorphingButton";
import WorkList from "../../WorkList";
import Forms from "../../Forms";
import ContactForm from "../../ContactForm";
//import HideOnScrollDown from "../../HideOnScrollDown";

const morphingButton = new MorphingButton();
const exitEnter = new ExitEnter();
const navigation = new Navigation();
const parallax = new Parallax();
const onScrollAnimations = new OnScrollAnimations();
const clipNavigation = new ClipNavigation();
//const cursor = new Cursor();
const workList = new WorkList();
const imagesAnimation = new ImagesAnimation();
const forms = new Forms();
const contactForm = new ContactForm();
//const hideOnScrollDown = new HideOnScrollDown();

const lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy-load"
});

export default class initBarbaWork {

    constructor() {
        this.page = this.extend();
        this.page.init();
    }

    extend() {
        return Barba.BaseView.extend({
            namespace: 'work',
            onEnter() {
                onScrollAnimations.destroy();
                parallax.destroy();
                navigation.init();
                clipNavigation.clipNavigation();
                lazyLoadInstance.update();
            },
            onEnterCompleted() {
                workList.init();
                onScrollAnimations.attach();
                parallax.attach();
                //cursor.init();
                morphingButton.init();
                imagesAnimation.init();
                forms.init();
                contactForm.init();
                //hideOnScrollDown.init();
                //sendPageView();
            },
            onLeave() {
                exitEnter.exit();
            },
            onLeaveCompleted() {
            },
        });
    }
}
