import { gsap } from "gsap";

window.cursorAnimationRequestId = undefined;

export default class Cursor {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            cursor: '.js-cursor',
            cursorCircle: '.js-cursor-circle',
            cursorArrow: '.js-cursor-arrow',
            initialColorEl: '.js-init-color',
        };

        this.defaults = Object.assign({}, _defaults, options);
        this.cursorInited = false;

        this.timeout = null;
        this.cursorText = null;
        this.cursorColor = null;
        this.allLinks = $(document).find('a');

        this.init();
    }

    // region init

    init() {
        // console.log('Cursor init()');

        // center cursor
        //var cursor = document.getElementsByClassName("js-cursor")[0];
        //cursor.style.left = window.innerWidth / 2+'px';
        //cursor.style.top = window.innerHeight / 2+'px';

        const _this = this;

        if (window.cursorAnimationRequestId) {
            cancelAnimationFrame(window.cursorAnimationRequestId);
            window.cursorAnimationRequestId = undefined;
        }

        function cursorMoveDom(e) {
            var cursor = document.getElementsByClassName("js-cursor")[0];

            if (cursor.classList.contains("hidden")) {
                cursor.classList.remove("hidden");
            }

            cursor.style.left=e.clientX+"px";
            cursor.style.top=e.clientY+"px";

            window.cursorAnimationRequestId = requestAnimationFrame(cursorMoveDom);
        }

        /*
        function cursorMove(e) {
            var $cursor = $('.js-cursor');

            if ($cursor.hasClass("hidden")) {
                $cursor.removeClass("hidden");
            }

            $cursor.css({
                'left': e.clientX+"px",
                'top': e.clientY+"px",
            });

            requestAnimationFrame(cursorMove);
        }
         */

        $('html, body').addClass('cursor-loaded');
        this.setCursorColor();

        window.addEventListener("mousemove", cursorMoveDom);

        $(document).on("mouseover", "[data-cursor]", function() {
            clearTimeout(_this.timeout);

            _this.cursorText = $(this).data('cursor');
            _this.cursorColor = $(this).data('cursor-color');

            _this.getCursorCircle().html(_this.cursorText);
            _this.getCursorCircle().find('.word');
            _this.getCursorCircle().find('.splitting').hide(0).removeClass('show').show(0).addClass('show');

            if (!_this.getCursor().hasClass('active')) {
                _this.getCursor().addClass('active');
            }

            if (typeof _this.cursorColor === 'undefined' && _this.getCursor().hasClass('colored')) {
                _this.resetCursor();
            } else if (typeof _this.cursorColor !== 'undefined') {
                _this.getCursor().addClass('colored');
                _this.getCursorCircle().css({ backgroundColor: _this.cursorColor })
            }
        });

        $(document).on("mouseout", "[data-cursor]",function() {
            _this.getCursorCircle().find(".splitting").removeClass("show");
            clearTimeout(_this.timeout);

            _this.timeout = setTimeout(function(){
                _this.getCursor().removeClass('active');

                if (_this.getCursor().hasClass('colored')) {
                    _this.resetCursor();
                }

                _this.getCursorCircle().html('');
            },250)
        });

        //$.each(this.allLinks, function(n,i) {
        $.each($(document).find('a'), function(n,i) {

            var linkText = $(i).data('cursor');
            var linkColor = $(i).data('cursor-color');

            if (typeof linkText === 'undefined') {
                if( $(i).prop('title')) {
                    $(i).attr('data-cursor', $(i).prop('title'));
                } else {
                    $(i).attr('data-cursor', $(i).text());
                }
            }

            if (typeof linkColor === 'undefined') {
                $(i).attr('data-cursor-color',  _this.getDefaultColor());
            }
        });


        /*
        $(document).on("mouseover", ".js-slide-link",function(){
            jQuery(this).hasClass("slider-controls__left")?
                jQuery(o).addClass("left"):
                jQuery(o).removeClass("left"),

            cursor.classList.contains("active")||(cursor.classList.add("active"),
                resetCursor(),

                jQuery(o).removeClass("hidden"))
        });

        $(document).on("mouseout", ".js-slide-link",function(){
            cursor.classList.remove("active"),
                jQuery(o).addClass("hidden")
        });
         */
    }

    // endregion

    // region Getters

    getInitialColorEl() {
        return $(this.defaults.initialColorEl);
    }

    getCursor() {
        return $(this.defaults.cursor);
    }

    getCursorCircle() {
        return $(this.defaults.cursorCircle);
    }

    getCursorArrow() {
        return $(this.defaults.cursorArrow);
    }

    getDefaultColor() {
        let initColor = this.getInitialColorEl().data('cursor-init-color');

        if (typeof initColor === 'undefined') {
            initColor = '#ffffff';
        }

        return initColor;
    }

    setCursorColor() {
        var tl = gsap.timeline().to(this.getCursorCircle(), {
            duration: 0.01,
            backgroundColor: this.getDefaultColor()
        });
        //this.getCursorCircle().css({ backgroundColor: this.getDefaultColor() })
    }

    // endregion

    resetCursor() {
        this.getCursor().removeClass("colored");
        this.getCursorCircle().attr("style", "");
        this.setCursorColor();
        this.init();
    }
}
