import { gsap, Expo } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

function workFadeOut(_this, currentAnchor) {
    /**
     * _this.oldContainer is the HTMLElement of the old Container
     */

    // const deferred = Barba.Utils.deferred();

    return new Promise((resolve) => {
        $('.js-work-item').off('mouseenter');
        $(document).off('mousemove');

        const workAnimationTl = gsap.timeline({
            onStart: () => {

            },
            onComplete: () => {
                currentAnchor = '';
            },
        });

        const previewCanvas = $('.js-work-preview-canvas');
        const workStageOut = $('.js-work-stage-out');
        const workTransitionLayer = $('.js-work-transition-layer');

        // $('body').addClass('is-mouse-disabled');

        let initialWidth = 0;
        let initialHeight = 0;
        let initialTop = 0;
        let initialLeft = 0;

        if (previewCanvas.length > 0) {
            initialWidth = previewCanvas.outerWidth();
            initialHeight = previewCanvas.outerHeight();
            initialTop = previewCanvas.offset().top;
            initialLeft = previewCanvas.offset().left;
        }

        const initColor = $(currentAnchor).data('work-color') || '#ffffff';
console.log(initColor);
        workAnimationTl.add('start')
            .to(workStageOut, {
                duration: 0.4,
                stagger: 0.1,
                autoAlpha: 0,
                y: '5%',
                skewY: '1.5deg',
                onStart: () => {
                    gsap.set(workTransitionLayer, {
                        top: initialTop - $(window).scrollTop(),
                        left: initialLeft,
                        width: initialWidth,
                        height: initialHeight,
                    });
                },
                ease: Expo.easeOut,
            }, 'start')
            .to(previewCanvas.find('canvas'), {
                duration: 0.2,
                autoAlpha: 0,
                onStart: () => {
                    gsap.set(previewCanvas, {
                        backgroundColor: initColor,
                    });
                },
            }, 'start')
            .set(workTransitionLayer, {
                backgroundColor: initColor,
            })
            .add('afterStart')
            .to(workTransitionLayer, {
                duration: 0.6,
                left: 0,
                width: "100vw",
                ease: Expo.easeOut,
            })
            .to(workTransitionLayer, {
                duration: 0.6,
                top: 0,
                height: "100vh",
                ease: Expo.easeOut,
                onComplete: () => {
                    // fix blink old container (homepage -> single work)
                    gsap.set($('body'), {
                        backgroundColor: initColor,
                    })
                    gsap.set(_this.oldContainer, {
                        autoAlpha: 0,
                    })
                    // fix blink body background-color
                    resolve('fadeOut() is resolved');
                },
            });
    });
}

function workScrollTop(_this) {
    return new Promise((resolve) => {
        gsap.set($('html, body'), {
            scrollTo: {
                y: 0,
                x: 0,
            },
            ease: Expo.easeInOut,
            onStart: () => {
                // LOCK HTML
                $('html').addClass('is-locked');
            },
            onComplete: () => {
                resolve('scrollTop() is resolved');
            },
        });
    });

    // return deferred.promise;
}

function workFadeIn(_this) {
    /**
     * _this.newContainer is the HTMLElement of the new Container
     * At _this stage _this.newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
     * Please note, _this.newContainer is available just after _this.newContainerLoading is resolved!
     */

    const workTransitionLayer = $('.js-work-transition-layer');

    let initColor = $(_this.newContainer).data('init-color');

    if (initColor === undefined) {
        initColor = '#ffffff';
    }

    gsap.set($('.js-page-transitions'), {
        backgroundColor: initColor,
    });

    return new Promise((resolve) => {
        const stageInElements = $(_this.newContainer).find('.js-stage-in');
        const stageInImage = $(_this.newContainer).find('.js-stage-in-image');

        gsap.set(stageInElements, {
            autoAlpha: 0,
            y: '50%',
            skewY: '2.5deg',
        });

        gsap.set(stageInImage, {
            autoAlpha: 0,
            y: '15%',
        });

        gsap.set(_this.newContainer, {
            autoAlpha: 1,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
        });

        gsap.to($(_this.newContainer), {
            duration: 0.4,
            autoAlpha: 1,
            ease: Expo.easeInOut,
            onStart: () => {
                gsap.set($('html, body'), {
                    gsap: {
                        y: 0,
                        x: 0,
                    },
                });

                gsap.to(workTransitionLayer, {
                    duration: 0.2,
                    autoAlpha: 0,
                    ease: Expo.easeOut,
                    onComplete: () => {
                        gsap.set(workTransitionLayer, {
                            clearProps: 'all',
                        });
                    },
                });

                gsap.to([stageInElements, stageInImage], {
                    duration: 0.6,
                    stagger:  0.1,
                    autoAlpha: 1,
                    y: '0%',
                    skewY: '0deg',
                    ease: Expo.easeOut,
                });
            },
            onComplete: () => {
                gsap.set(_this.newContainer, {
                    clearProps: 'all',
                });

                resolve('NEXT CASE - fadeIn() is resolved -> EVERYTHING IS DONE!');
                _this.done();

                // UNLOCK HTML
                $('html').removeClass('is-locked');
            },
        });
    });
}

export { workFadeOut, workFadeIn, workScrollTop }
