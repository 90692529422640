import { gsap } from 'gsap'

export default class Counter {

    constructor() {
        this.counters = document.querySelectorAll(".js-counter");

        if (this.counters.length > 0) {
            for (let counter of this.counters) {
                this.init(counter);

                document.addEventListener('scroll', () => {
                    this.init(counter);
                });
            }
        }
    }

    init(counter) {
        if (!counter.dataset.value) {
            return;
        }

        Number.prototype.numberFormat = function (decimals, dec_point, thousands_sep) {
            dec_point = typeof dec_point !== 'undefined' ? dec_point : ',';
            thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : '.';

            let parts = this.toFixed(decimals).split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

            return parts.join(dec_point);
        };

        if (counter.getBoundingClientRect().top - (window.innerHeight * 0.85) > 0 &&
            counter.getBoundingClientRect().top > 0) {
            return;
        }

        if (!counter.classList.contains('is-animated')) {
            counter.classList.add('is-animated');
            let counterVal = {
                let: 0
            };

            let decimals = counter.dataset.value % 1 === 0 ? 0 : 2;

            gsap.to(counterVal, {
                duration: 2,
                let: counter.dataset.value,
                onUpdate: function () {
                    counter.innerHTML = counterVal.let.numberFormat(decimals);
                },
                ease: Circ.easeOut
            });
        }
    }
}
