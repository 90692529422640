import { gsap, Expo, Power0 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

import SplitText from "../../../bundles/SplitText";
import ExitEnter from "../../ExitEnter";
const exitEnter = new ExitEnter();

function fadeOut(_this) {
    /**
     * _this.oldContainer is the HTMLElement of the old Container
     */

    // const deferred = Barba.Utils.deferred();

    return new Promise((resolve) => {
        gsap.to($('.js-page-wrapper'), {
            duration: 0.2,
            autoAlpha: 0,
            ease: Expo.easeInOut,
            onStart: () => {
                gsap.to($(_this.oldContainer), {
                    duration: 0.2,
                    autoAlpha: 0,
                    ease: Power0.easeNone,
                });

                exitEnter.exit();
            },
            onComplete: () => {
                resolve('fadeOut() is resolved');
            },
        });
    });
}

function scrollTop(_this) {
    return new Promise((resolve) => {
        gsap.set($('html, body'), {
            scrollTo: {
                y: 0,
                x: 0,
            },
            ease: Expo.easeInOut,
            delay: 0.2,
            onStart: () => {
                // LOCK HTML
                $('html').addClass('is-locked');
            },
            onComplete: () => {
                resolve('scrollTop() is resolved');
            },
        });
    });

    // return deferred.promise;
}

function fadeIn(_this) {
    /**
     * _this.newContainer is the HTMLElement of the new Container
     * At _this stage _this.newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
     * Please note, _this.newContainer is available just after _this.newContainerLoading is resolved!
     */

    let initColor = $(_this.newContainer).data('init-color');
    const stagePreTitleEl = $(_this.newContainer).find('.js-animation-stage-pre-title');
    const stageSplitTextEl = $(_this.newContainer).find('.js-animation-stage-title');
    const stageEl = $(_this.newContainer).find('.js-animation-stage-element');
    const stageMenuEl = $(_this.newContainer).find('.js-menu-stage');

    if (initColor === undefined) {
        initColor = '#ffffff';
    }

    gsap.set($(_this.newContainer), {
        autoAlpha: 0,
    });

    gsap.set($('.js-page-transitions'), {
        backgroundColor: initColor,
    });

    _this.stagePreHeaderTl = gsap.timeline({
        paused: true,
    });

    _this.stageHeaderTl = gsap.timeline({
        paused: true,
    });

    _this.stageHeaderElTl = gsap.timeline({
        paused: true,
    });

    _this.stageHeaderMenuElTl = gsap.timeline({
        paused: true,
    });

    exitEnter.enterSet();

    if (stagePreTitleEl.length > 0) {
        const bfSplitText = new SplitText(stagePreTitleEl, {
            type: 'lines',
        });

        gsap.set(stagePreTitleEl, {
            perspective: 400,
        });

        _this.stagePreHeaderTl.add('start')
            .from(bfSplitText.lines, {
                duration: 1.2,
                stagger: 0.1,
                opacity: 0,
                y: '75%',
                skewY: '-0.75deg',
                ease: Expo.easeOut,
            }, 'start');
    }

    if (stageSplitTextEl.length > 0) {
        const bfSplitText = new SplitText(stageSplitTextEl, {
            type: 'lines',
        });

        gsap.set(stageSplitTextEl, {
            perspective: 400,
        });

        _this.stageHeaderTl.add('start')
            .from(bfSplitText.lines, {
                duration: 1.2,
                stagger: 0.1,
                opacity: 0,
                y: '75%',
                skewY: '-0.75deg',
                ease: Expo.easeOut,
            }, 'start');
    }

    if (stageEl.length > 0) {

        gsap.set(stageEl, {
            perspective: 400,
        });

        _this.stageHeaderElTl.add('start')
            .from(stageEl, {
                duration: 0.8,
                stagger: 0.075,
                opacity: 0,
                y: '50%',
                skewY: '-2.5deg',
                delay: 0.2,
                ease: Expo.easeOut,
            }, 'start');
    }

    // if (stageMenuEl.length > 0) {
    //
    //     _this.stageHeaderMenuElTl.add('start')
    //         .staggerFrom(stageMenuEl, 0.8, {
    //             opacity: 0,
    //             delay: 0.2,
    //             ease: Expo.easeOut,
    //         }, 0.075, 'start');
    // }

    return new Promise((resolve) => {
        gsap.to($('.js-page-transitions'), {
            duration: 0.4,
            width: '100%',
            ease: Expo.easeOut,
            backgroundColor: initColor,
            onComplete: () => {

                gsap.to($('.js-page-transitions'), {
                    duration: 0.6,
                    y: 0,
                    ease: Expo.easeInOut,
                    onComplete: () => {
                        gsap.set($('body'), {
                            clearProps: 'all',
                        });

                        gsap.set($('body'), {
                            backgroundColor: initColor,
                        });

                        exitEnter.enter(1.2);

                        if(stagePreTitleEl.length > 0) {
                            _this.stagePreHeaderTl.play();
                        }

                        if(stageSplitTextEl.length > 0) {
                            _this.stageHeaderTl.play();
                        }

                        if(stageEl.length > 0) {
                            _this.stageHeaderElTl.play();
                        }

                        // if(stageMenuEl.length > 0) {
                        //     _this.stageHeaderMenuElTl.play();
                        // }

                        gsap.to($(_this.newContainer), {
                            duration: 0.6,
                            autoAlpha: 1,
                            onComplete: () => {
                                _this.done();

                                resolve('fadeIn() is resolved -> EVERYTHING IS DONE!');

                                // UNLOCK HTML
                                $('html').removeClass('is-locked');
                                if ($(_this.newContainer).data('namespace') === 'contact') {
                                    $('#dropfiles-cf7').remove();
                                    const array = Array.from(document.getElementsByTagName('script'));
                                    array.forEach((el) => {
                                        const S = el.src;
                                        const contains = S.includes('dropfiles-cf7');
                                        if (contains === true) {
                                            const script = document.createElement('script');
                                            script.src = el.src;
                                            script.setAttribute("id", 'dropfiles-cf7');
                                            document.body.appendChild(script);
                                        }
                                    });
                                }
                            },
                        });

                        gsap.to($('.js-page-transitions'), {
                            duration: 0.6,
                            autoAlpha: 0,
                            onComplete: () => {
                                gsap.set($('.js-page-transitions'), {
                                    clearProps: "all",
                                });
                            },
                        });
                    },
                });
            },
        });
    });
}

export { fadeOut, fadeIn, scrollTop }