(function ($) {

    EWZBundleRecaptchaBundleValidatorConstraintsIsTrue = function () {
        this.message = null;

        this.validate = function (value, element) {
            var self = this;
            var $captchaInput = $('#' + element.id);

console.log($captchaInput);

            return [];
        };
    };

})(jQuery);
