import ImagesAnimation from "../../ImagesAnimation";
import ChangeColor from "../../ChangeColor";
import ExitEnter from "../../ExitEnter";
import Navigation from "../../Navigation";
import OnScrollAnimations from "../../OnScrollAnimations";
import LazyLoad from "vanilla-lazyload";
import SvgHand from "../../SvgHand";
import Parallax from "../../Parallax";
import MorphingButton from "../../MorphingButton";
import TestimonialLetterAnimationSlider from "../../TestimonialLetterAnimationSlider";
import Forms from "../../Forms";
import ContactForm from "../../ContactForm";
//import Cursor from "../../Cursor";
import ClipNavigation from "../../ClipNavigation";
//import HideOnScrollDown from "../../HideOnScrollDown";

const morphingButton = new MorphingButton();
const colorChange = new ChangeColor();
const exitEnter = new ExitEnter();
const navigation = new Navigation();
const onScrollAnimations = new OnScrollAnimations();
const svgHand = new SvgHand();
const parallax = new Parallax();
const testimonialLetterAnimationSlider = new TestimonialLetterAnimationSlider();
const imagesAnimation = new ImagesAnimation();
const forms = new Forms();
const contactForm = new ContactForm();
const clipNavigation = new ClipNavigation();
//const hideOnScrollDown = new HideOnScrollDown();

//const cursor = new Cursor();

const lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy-load"
});

export default class initBarbaAbout {

    constructor() {
        this.page = this.extend();
        this.page.init();
    }

    extend() {
        return Barba.BaseView.extend({
            namespace: 'about',
            onEnter() {
                //console.log('about onEnter');

                colorChange.destroy();
                parallax.destroy();
                onScrollAnimations.destroy();
                navigation.init();
                clipNavigation.clipNavigation();

                // lazy load update on page change
                lazyLoadInstance.update();
            },
            onEnterCompleted() {
                //console.log('about onEnterCompleted');

                //cursor.init();
                svgHand.attach();
                colorChange.attach();
                parallax.attach();
                onScrollAnimations.attach();
                //sendPageView();
                morphingButton.init();
                testimonialLetterAnimationSlider.init();
                imagesAnimation.init();
                forms.init();
                contactForm.init();
                //hideOnScrollDown.init();
            },
            onLeave() {
                exitEnter.exit();
            },
            onLeaveCompleted() {

            },
        });
    }
}
