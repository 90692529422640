import { gsap, Expo } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin'
gsap.registerPlugin(CSSPlugin);
import 'lettering.js';
import 'jquery-touchswipe';
import SplitText from "../bundles/SplitText";

var isFF;

$.fn.onCustom=function(t,n){
    return this.each((function(i,r){var o=$(r).data("listeners");o&&o[t]&&o[t].push(n)})),this
}

$.fn.triggerCustom=function(t,n){
    return this.each((function(i,r){for(var o=$(r).data("listeners")[t],s=0;s<o.length;s++)o[s]({},n)})),this
}

$.fn.offCustom=function(t){
    return this.each((function(n,i){var r=$(i).data("listeners");r&&r[t]&&(r[t]=[])})),this
}

export default class TestimonialLetterAnimationSlider {

    constructor(options) {

        const _defaults = {
            'countOfQuotes': 0,
            'activeQuote': 1,
            'partsCount': 7,
            'quoteAnimating': false,
            'isMobile': false,
            'animationDuration': 500,
            'animationDelay': 200,

            'quoteContainer': '.js-about-quotes',
            'quotePrefixSelector': '#quote-',
            'quoteNavArrowWrapper': '.c-about-quotes__nav-arrows-wrapper',
            'quoteNavLinesWrapper': '.c-about-quotes__nav-lines-wrapper',
            'quoteNavLine': '.c-about-quotes__nav-line',
            'quoteNavArrowLeft': '.c-about-quotes__nav-arrow-left',
            'quoteNavArrowRight': '.c-about-quotes__nav-arrow-right',
            'quoteTextElement': '.c-about-quotes__quote-text',
        }

        this.defaults = Object.assign({}, _defaults, options);

        isFF = $('html').hasClass('is-firefox');
        this.pageLoaded = true;
        this.ww = $(window).width();
        this.activeQuoteElement = null;
        this.containerOffsetLeft = null;
        this.containerWidth = null;

        this.init();
    }

    init() {
        //console.log('init quote slider');

        if (this.getActiveQuote().length) {
            this.defaults.countOfQuotes = this.getQuoteContainer().data('quotes-count');

            this.initEvents();
            this.initNavLines();
            this.initSlider();
        }
    }

    initEvents() {
        $(document).on('mouseenter', '.c-about-quotes__nav-arrows-wrapper', (ev) => this.onArrowsWrapperMouseEnter(ev));
        $(document).on('mouseleave', '.c-about-quotes__nav-arrows-wrapper', (ev) => this.onArrowsWrapperMouseLeave(ev));
        $(document).on('mouseenter', '.c-about-quotes__nav-arrow-hover-area-right', (ev) => this.onArrowHoverAreaRightMouseEnter(ev));
        $(document).on('mouseenter', '.c-about-quotes__nav-arrow-hover-area-left', (ev) => this.onArrowHoverAreaLeftMouseEnter(ev));
        $(document).on('mouseleave', '.c-about-quotes__nav-arrow-hover-area-right', (ev) => this.onArrowHoverAreaRightMouseLeave(ev));
        $(document).on('mouseleave', '.c-about-quotes__nav-arrow-hover-area-left', (ev) => this.onArrowHoverAreaLeftMouseLeave(ev));
        $(document).on('mouseleave', '.c-about-quotes__nav-arrow-hover-area-left', (ev) => this.onArrowHoverAreaLeftMouseLeave(ev));
        $(document).on('click', '.c-about-quotes__nav-arrow-hover-area-left', (ev) => this.goPrev(ev));
        $(document).on('click', '.c-about-quotes__nav-arrow-hover-area-right', (ev) => this.goNext(ev));
        $(window).on('resize', (ev) => this.onResize(ev));
    }

    initNavLines() {
        //console.log('initNavLines');

        //const navLinesWrapperWidth = this.getQuoteNavLinesWrapper().outerWidth();
        //const lineWidth = navLinesWrapperWidth / this.defaults.countOfQuotes;

        const _this = this;

        this.getQuoteNavLine().each(function() {
           //$(this).css({'width':lineWidth+'px'});
            //console.log($(this));
           $(this).css({'width':(100 / _this.defaults.countOfQuotes)+'%'});
        });
    }

    initSlider() {
        var _this = this;
        this.activeQuoteElement = this.getActiveQuote();

        if (!this.activeQuoteElement.length) {
            return;
        }

        this.activeQuoteElement.css({
            display: "block"
        });

        this.containerOffsetLeft = this.activeQuoteElement.offset().left;
        this.containerWidth = this.activeQuoteElement.width();

        this.getQuoteNavArrowWrapper().each(function(t,n){
            var i = this.showAnimation($(n).find(this.defaults.quoteNavArrowLeft));
            var r = this.showAnimation($(n).find(this.defaults.quoteNavArrowRight));

            //$(n).onCustom("screen-position",function(t,e){
            //e.screenPosition > 0 && (e.$el.offCustom("screen-position"),i.play(),r.play())
            i.play();
            r.play();
            //}.bind(this))
        }.bind(this));

        /*this.activeQuoteElement.each(function(t,n) {
            var i = this.activeQuoteElement.find(".c-about-quotes__quote-text span");
            var o = this.splitMultilineToSeparateSpans({
                    $container: i,
                    className: "c-about-quotes__quote-line",
                    transparent: true
                });

            //$(n).onCustom("screen-position",function(t,e){
                //if (e.screenPosition > 0) {
                    //e.$el.offCustom("screen-position");
                    this.animateTextByLines(o, this.defaults.animationDuration, this.defaults.animationDelay,
                        function(){
                            o.cancel(false);
                            this.quoteAnimating = false;
                        }.bind(this),false,30,0)
                //}
            //}.bind(this));
        }.bind(this));*/

        this.activeQuoteElement.find(".c-about-quotes__quote-author").each(function(t,n){
            var a = this.activeQuoteElement.find(".c-about-quotes__quote-author");
            var i = this.showAnimation(this.activeQuoteElement.find(".c-about-quotes__quote-author p"));

            //$(n).onCustom("screen-position",function(t,e) {
            //if (e.screenPosition > 0) {
            //e.$el.offCustom("screen-position");
            //e.$el.css({transition:"none"}).addClass("show");
            a.css({transition:"none"}).addClass("show");
            i.play();

            setTimeout((function() {
                //e.$el.css({transition:""})
                a.css({transition:""});
            }),100);
            //}
            //}.bind(this))
        }.bind(this));

        $("#quote-"+this.activeQuote+" span.row").addClass("show");

        $(".c-about-quotes__quotes-wrapper").swipe({
            swipeLeft:function(t,e){
                //console.log('_this.goNext()');
                _this.goNext()
            },
            swipeRight:function(t,e){
                //console.log('_this.goPrev()');
                _this.goPrev()
            }
        });

        $(".c-about-quotes__nav-arrows-wrapper").swipe({
            swipeLeft:function(t,e){
                //console.log('_this.goNext()');
                _this.goNext()
            },
            swipeRight:function(t,e){
                //console.log('_this.goPrev()');
                _this.goPrev()
            }
        });
    }

    //
    getQuoteContainer() {
        return $(this.defaults.quoteContainer);
    }

    getActiveQuote() {
        return $(this.defaults.quotePrefixSelector+this.defaults.activeQuote)
    }

    getQuoteNavArrowWrapper() {
        return $(this.defaults.quoteNavArrowWrapper)
    }

    getQuoteNavLinesWrapper() {
        return $(this.defaults.quoteNavLinesWrapper)
    }

    getQuoteNavLine() {
        return $(this.defaults.quoteNavLine)
    }

    getQuoteTextElement() {
        return $(this.defaults.quoteTextElement)
    }

    getPrevQuoteNumber() {
        return 1 !== this.defaults.activeQuote ? this.defaults.activeQuote-1 : this.defaults.countOfQuotes
    }

    getNextQuoteNumber(){
        return this.defaults.activeQuote !== this.defaults.countOfQuotes ? this.defaults.activeQuote+1 : 1
    }
    //

    animateTextByLines(t,e,n,i,r,o,l) {
        //console.log('animateTextByLines');

        var u = gsap.timeline();
        var c = t.$container.find("."+t.className);

        return  o=o||30,
            l=l||0,
            t.animated=!0,
            u.staggerFromTo(
                c,e/1e3,
                {y:o,opacity:.01},
                {y:0,opacity:1,ease:Expo.easeOut},
                n/1e3,0
            ),
            u.delay(l/1e3),
            u.add((function(){
                c.css({transform:"",opacity:""}),
                r&&t.cancel(!0),
                i&&i()
            })),
            u.play(),
            c.length
    }

    onArrowsWrapperMouseEnter() {
        //console.log('onArrowsWrapperMouseEnter');

        $(this.defaults.quoteNavArrowLeft).addClass("area-hover");
        $(this.defaults.quoteNavArrowRight).addClass("area-hover");
    }

    onArrowsWrapperMouseLeave() {
        //console.log('onArrowsWrapperMouseLeave');

        $(this.defaults.quoteNavArrowLeft).removeClass("area-hover");
        $(this.defaults.quoteNavArrowRight).removeClass("area-hover");
    }

    removeAllClasses() {
        //console.log('removeAllClasses');

        for(var t=0; t<this.defaults.countOfQuotes; t++){
            var e="#line"+(t+1);
            $(e).removeClass("prev-active");
            $(e).removeClass("prev-active-arrow-hover");
            $(e).removeClass("next-active");
            $(e).removeClass("next-active-arrow-hover");
            $(e).removeClass("active")
        }
    }

    setNewClasses (t){
        //console.log('setNewClasses');

        var e="#line"+this.defaults.activeQuote;
        $(e).addClass("active");

        var n = "#line"+this.getPrevQuoteNumber();
        $(n).addClass("prev-active");

        -1!==t||this.defaults.isMobile||$(n).addClass("prev-active-arrow-hover");

        var i="#line"+this.getNextQuoteNumber();
        $(i).addClass("next-active");

        1!==t||this.defaults.isMobile||$(i).addClass("next-active-arrow-hover");
    }

    setNextActiveQuote(t) {
        //console.log('setNextActiveQuote');

        this.defaults.activeQuote = -1 === t ? this.getPrevQuoteNumber() : this.getNextQuoteNumber()
    }

    onArrowHoverAreaRightMouseEnter() {
        //console.log('onArrowHoverAreaRightMouseEnter');

        $(".next-active").addClass("next-active-arrow-hover");
    }

    onArrowHoverAreaLeftMouseEnter() {
        //console.log('onArrowHoverAreaLeftMouseEnter');

        $(".prev-active").addClass("prev-active-arrow-hover")
    }

    onArrowHoverAreaRightMouseLeave() {
        //console.log('onArrowHoverAreaRightMouseLeave');

        $(".next-active").removeClass("next-active-arrow-hover")
    }

    onArrowHoverAreaLeftMouseLeave() {
        //console.log('onArrowHoverAreaLeftMouseLeave');

        $(".prev-active").removeClass("prev-active-arrow-hover")
    }

    animateLetters (letters, partsCount, indexOfSlide, isNext, r) {
        //console.log('animateLetters');

        for(var o=0, s=0; s <= this.defaults.partsCount; s++) {
            for (var a = isNext ? this.defaults.partsCount - partsCount : partsCount,
                     l = this.containerOffsetLeft + partsCount * this.containerWidth / this.defaults.partsCount + (0 === partsCount ? -15 : 0),
                     u = this.containerOffsetLeft + (partsCount + 1) * this.containerWidth / this.defaults.partsCount,
                     c = 0; c<letters.length; c++
            ) {
                var h = letters.eq(c);
                var p = h.offset().left;

                if(p >= l && p < u) {
                    var f= 50*a+Math.round(170*Math.random())+(r ? 0 : 40);
                    h.css("transition-delay",f+"ms");
                    o = Math.max(o,f)
                }
            }
            isNext ? partsCount-- :partsCount++
        }

        var d = $("#quote-"+indexOfSlide+" span.row");

        d.toggleClass("show",!r).addClass("accelerated");

        clearTimeout(d.attr("data-timer"));

        var m = setTimeout((function(){
            d.removeClass("accelerated")
        }),o+400);

        d.attr("data-timer", m)
    }

    removeQuote (isNext) {
        //console.log('removeQuote');

        var indexOfSlide = this.defaults.activeQuote;
        var letters = ($("#quote-"+indexOfSlide+" span.row"), $("#quote-"+indexOfSlide+" span.row > span"));
        var partsCount = isNext ? this.defaults.partsCount : 0;

        this.animateLetters(letters, partsCount, indexOfSlide, isNext,true)
    }

    showQuote (indexOfSlide, isNext) {
        //console.log('showQuote');

        $("#quote-"+indexOfSlide+" span.row");
        var letters=$("#quote-"+indexOfSlide+" span.row > span"),
            partsCount = isNext ? this.defaults.partsCount : 0;

        this.animateLetters(letters, partsCount, indexOfSlide, isNext,false)
    }

    goPrev() {
        //console.log('goPrev');
        //console.log(this.defaults.quoteAnimating);

        if(!this.defaults.quoteAnimating) {
            this.defaults.quoteAnimating = true;

            setTimeout(function(){
                this.defaults.quoteAnimating = false;
            }.bind(this),1000);

            this.prepareQuote(this.defaults.activeQuote);
            this.removeQuote(false);

            var _this = this;
            var prevQuoteNumber = this.getPrevQuoteNumber();

            $("#quote-"+this.defaults.activeQuote+" .c-about-quotes__quote-author").removeClass("show");
            this.prepareQuote(prevQuoteNumber);

            setTimeout((function(){
                $("#quote-"+prevQuoteNumber+" .c-about-quotes__quote-author").addClass("show");
                _this.showQuote(prevQuoteNumber,false);
            }),10);

            this.removeAllClasses();
            this.setNextActiveQuote(-1);
            this.setNewClasses(-1);
        }
    }

    goNext() {
        //console.log('goNext');
        //console.log(this.defaults.quoteAnimating);

        if(!this.defaults.quoteAnimating){
            this.defaults.quoteAnimating = true;

            setTimeout(function(){
                this.defaults.quoteAnimating = false;
            }.bind(this),1000);

            this.prepareQuote(this.defaults.activeQuote);
            this.removeQuote(true);

            var _this = this;
            var nextQuoteNumber = this.getNextQuoteNumber();

            $("#quote-"+this.defaults.activeQuote+" .c-about-quotes__quote-author").removeClass("show");
            this.prepareQuote(nextQuoteNumber);

            setTimeout((function(){
                $("#quote-"+nextQuoteNumber+" .c-about-quotes__quote-author").addClass("show");
                _this.showQuote(nextQuoteNumber,true)
            }),10);

            this.removeAllClasses();
            this.setNextActiveQuote(1);
            this.setNewClasses(1);
        }
    }

    onResize() {
        //console.log('onResize');

        if ($("#quote-"+this.defaults.activeQuote).length) {
            this.ww = $(window).width();
            this.containerOffsetLeft = $("#quote-"+this.defaults.activeQuote).offset().left;
            this.containerWidth = $("#quote-"+this.defaults.activeQuote).width();
        }
    }

    showAnimation(element) {
        //console.log('showAnimation');

        element.css({
            "will-change":"opacity, transform"
        });

        var tl = gsap.timeline({paused: true});

        tl.staggerFromTo(element.toArray(), this.defaults.animationDuration/1000, {
            y:30,
            opacity:.01
        },{
            y:0,
            opacity:1,
            ease:Expo.easeOut
        }, this.defaults.animationDelay/1000, 0);


        tl.add((function(){
            element.css({
                transform: "",
                opacity: ""
            })
        }));

        return tl;
    }

    splitMultilineToSeparateSpans(t){
        //console.log('splitMultilineToSeparateSpans');

        var n = t.$container[0].innerHTML;
        var i = (t.$container.text().split(" "), {
            $container: t.$container,
            className: t.className,
            cancel:function(e){
                //app.vent.off("resize-started", r);
                //app.vent.off("resize-ended", o);
                window.removeEventListener('resize-started', r);
                window.removeEventListener('resize-end', o);
                e && (t.$container[0].innerHTML = n);
            }
        });

        var r = function(){
            t.$container[0].innerHTML = n;
        }

        var o = function(){
            /*var n = new CSSPlugin(t.$container, {
                type: "lines",
                linesClass: t.className
            });*/

            var n = new SplitText(t.$container, {
                type: 'lines',
                linesClass: t.className
            });

            $(n.lines).css({
                display: t.autoWidth && (this.defaults.isIE || this.defaults.isEdge) ? "inline-block" : "block",
                width: isFF ? "-moz-fit-content" : "fit-content",
                opacity: t.transparent && !i.animated ? "0" : "",
                "will-change": "opacity, transform"
            });
        }

        //return this.pageLoaded && o(), app.vent.on("resize-started", r), app.vent.on("resize-ended", o),i
        return this.pageLoaded && o(), window.addEventListener('resize-started', r), window.addEventListener('resize-end', o),i
    }

    prepareQuote(t) {
        //console.log('prepareQuote');

        var e=$("#quote-"+t).css("display","block").find(".c-about-quotes__quote-text span.row");
        e.hasClass("splitted")||e.addClass("splitted").lettering()
    }
}