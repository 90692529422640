export default class ReadMoreBlock {
    constructor() {
        const _defaults = {
            slider: '.js-read-more-swiper',
            sliderPrevious: '.js-read-more-previous',
            sliderNext: '.js-read-more-next',
            sliderPagination: '.js-read-more-pagination',
        };

        this.defaults = Object.assign({}, _defaults);

        if (this.getSlider().length > 0) {
            this.initSliders();
        }
    }

    init() {

    }

    // region Getters
    getSlider() {
        return document.querySelectorAll(this.defaults.slider);
    }

    // endregion


    // region Slider

    initSliders() {
        for (let slider of this.getSlider()) {
            const prev = slider.querySelector(this.defaults.sliderPrevious);
            const next = slider.querySelector(this.defaults.sliderNext);

            const swiper = new Swiper(slider, {
                effect: "slide",
                loop: true,
                speed: 800,
                slidesPerView: 3,
                loopFillGroupWithBlank: true,
                slidesPerGroup: 3,
                pagination: {
                    el: '.js-read-more-pagination',
                    type: 'fraction',
                },
                navigation: {
                    prevEl: prev,
                    nextEl: next,
                },
                breakpoints: {
                    480: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        spaceBetween: 20
                    },
                    720: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                    }
                }
            });
        }
    }

    // endregion
}
