import CustomizeBundle from '../validation/customizeBundle';
import Window from './Window';
import Translator from 'bazinga-translator';

export default function showFormErrorsWindow (container) {
    const $formattedFormErrors = CustomizeBundle.getFormattedFormErrors(container);
    const $window = $('#js-window');

    let errorMessage = typeof $(container).data('error-message') !== 'undefined' ? $(container).data('error-message') : Translator.trans('Please check the entered values.<br>');

    const $errorListHtml = '<div class="text-left">'
        + errorMessage
        + $formattedFormErrors[0].outerHTML
        + '</div>';

    if ($window.length === 0) {
        // eslint-disable-next-line no-new
        new Window({
            content: $errorListHtml
        });
    } else {
        $window.filterAllNodes('.js-window-validation-errors')
            .html($errorListHtml)
            .removeClass('u-visually-hidden');
    }
}
