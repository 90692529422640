import { gsap } from 'gsap'

export default class Accordion {
    constructor() {
        this.accordion = document.querySelectorAll('.js-accordion');

        if (this.accordion.length > 0) {
            this.accordion.forEach(accordion => {
                let mono = false;
                if (accordion.classList.contains("is-mono")) {
                    mono = true;
                }

                if (accordion.classList.contains("is-mobile") && window.innerWidth < 800) {
                    this.initAccordion(accordion, mono);
                } else if (!accordion.classList.contains("is-mobile")) {
                    this.initAccordion(accordion, mono);
                }
            });
        }
    }

    /**
     * Initialisation of accordion
     * @param {HTMLElement} accordion
     * @param {string} mono
     */
    initAccordion(accordion, mono) {
        const accordionSingles = accordion.querySelectorAll('.js-accordion-single');

        accordionSingles.forEach(accordionSingle => {
            this.accordionController(accordionSingle, accordion, mono);
        });
    }

    /**
     * Controller for single accordion item (open/close)
     * @param {HTMLElement} accordionSingle
     * @param {HTMLElement} accordion
     * @param {string} mono
     */
    accordionController(accordionSingle, accordion, mono) {
        const accordionHeader = accordionSingle.querySelector('.js-accordion-header');
        const accordionContent = accordionSingle.querySelector('.js-accordion-panel');

        gsap.set(accordionContent, {
            height: 0
        });

        accordionHeader.addEventListener('click', (ev) => {
            ev.preventDefault();

            if (ev.currentTarget.classList.contains('is-opened')) {
                this.closeAccordion(ev.currentTarget, accordionContent);
                return;
            }

            this.openAccordion(ev.currentTarget, accordionContent, accordion, mono);
        });
    }

    /**
     * Close single accordion item
     * @param {HTMLElement} accordionHeader
     * @param {HTMLElement} accordionContent
     */
    closeAccordion(accordionHeader, accordionContent) {
        accordionHeader.classList.remove('is-opened');

        gsap.to(accordionContent, {
            duration: 0.4,
            height: 0,
            ease: Power3.easeIn
        });
    }

    /**
     * Open single accordion item
     * @param {HTMLElement} accordionHeader
     * @param {HTMLElement} accordionContent
     * @param {HTMLElement} accordion
     * @param {string} mono
     */
    openAccordion(accordionHeader, accordionContent, accordion, mono) {
        if (mono) {
            const accordionSingles = accordion.querySelectorAll('.js-accordion-single');

            accordionSingles.forEach(accordionSingle => {
                const accordionHeaderInactive = accordionSingle.querySelector('.js-accordion-header');
                const accordionContentInactive = accordionSingle.querySelector('.js-accordion-panel');

                if (accordionHeader === accordionHeaderInactive) {
                    return;
                }

                accordionHeaderInactive.classList.remove('is-opened');

                gsap.to(accordionContentInactive, {
                    duration: 0.4,
                    height: 0,
                    ease: Power3.easeIn
                });
            });
        }

        accordionHeader.classList.add('is-opened');
        let height = 0;

        gsap.set(accordionContent, {
            height: "auto",
            onComplete: () => {
                height = accordionContent.clientHeight;

                gsap.set(accordionContent, {
                    height: 0,
                    onComplete: () => {
                        gsap.to(accordionContent, {
                            duration: 0.5,
                            height: height,
                            ease: Power3.easeOut,
                            onComplete: () => {
                                accordionContent.style.height = "auto";
                            }
                        })
                    }
                })
            }
        });
    }
}
