import constant from '../utils/constant';

(function (window) {

    const SymfonyComponentValidatorConstraintsNotNull = function () {
        this.message = '';

        this.validate = function (value, element) {
            const errors = [];
            const f = FpJsFormValidator;

            let isValueNull;

            if (element.type === constant('\\Symfony\\Component\\Form\\Extension\\Core\\Type\\CheckboxType::class')) {
                isValueNull = value === null;
            } else {
                isValueNull = f.isValueEmty(value);
            }

            if (isValueNull) {
                errors.push(this.message.replace('{{ value }}', String(value)));
            }

            return errors;
        };
    };

    window.SymfonyComponentValidatorConstraintsNotNull = SymfonyComponentValidatorConstraintsNotNull;

})(window);
