import { Expo, gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

function nextCaseScrollCaseToTop(_this) {
    /**
     * _this.oldContainer is the HTMLElement of the old Container
     */

    // const deferred = Barba.Utils.deferred();
    // console.log('BEFORE MY REQUIRED PROMISE');

    const prevCaseExists = $(_this.oldContainer).find('.js-prev-project-header').length > 0;

    return new Promise((resolve) => {
        const stageOutElements = $(_this.oldContainer).find('.js-stage-out');

        const tl = gsap.timeline({
            onStart: () => {
                $('html').addClass('is-next-case-loading');
                $(_this.oldContainer).find('.js-next-project-header').addClass('is-loading');

                gsap.to(stageOutElements, {
                    duration: 0.4,
                    autoAlpha: 0,
                    height: 0,
                    y: '-50%',
                    skewY: '-2.5deg',
                    delay: 0.2,
                    ease: Expo.easeOut,
                    stagger: 0.05,
                    onComplete: () => {
                        stageOutElements.remove();
                    }
                });

                gsap.set($(_this.oldContainer).find('.js-next-project-header'), {
                    position: 'fixed',
                    right: 0,
                    top: 'auto',
                    bottom: 0,
                    width: $(_this.oldContainer).find('.js-next-project-header').outerWidth()+'px',
                    height: $(_this.oldContainer).find('.js-next-project-header').outerHeight()+'px',
                });
            },
            onComplete: () => {
                gsap.set($('body'), {
                    backgroundColor: $(_this.oldContainer).find('.js-next-project-header').data('work-color'),
                });
                // LOCK HTML
                //$('html').addClass('is-locked');
                resolve(`NEXT CASE - fadeOut() is resolved`);
            },
        });

        tl.add('start');

        tl.to($('html, body'), {
            duration: 1,
        }, 'start');

        // tl.to(stageOutElements, {
        //     duration: 0.4,
        //     autoAlpha: 0,
        //     height: 0,
        //     y: '-50%',
        //     skewY: '-2.5deg',
        //     delay: 0.2,
        //     ease: Expo.easeOut,
        //     stagger: 0.05,
        // }, 'start');

        if (prevCaseExists) {
            $(_this.oldContainer).find('.js-work-title').removeClass('smaller');
            $(_this.oldContainer).find('.js-work-description').removeClass('smaller');
            /*tl.to($(_this.oldContainer).find('.js-work-title'), {
                duration: 0.4,
                fontSize: $(_this.oldContainer).find('.js-work-heading-title').css('font-size'),
                letterSpacing: $(_this.oldContainer).find('.js-work-heading-title').css('letter-spacing'),
                ease: Expo.easeInOut,
            }, 'start');

            tl.to($(_this.oldContainer).find('.js-work-description'), {
                duration: 0.4,
                fontSize: $(_this.oldContainer).find('.js-work-heading-description').css('font-size'),
                letterSpacing: $(_this.oldContainer).find('.js-work-heading-description').css('letter-spacing'),
                ease: Expo.easeInOut,
            }, 'start');*/

            tl.to($(_this.oldContainer).find('.js-next-project-header'), {
                duration: 0.4,
                height: '100%',
                ease: Expo.easeInOut,
            }, 'start');

            tl.to($(_this.oldContainer).find('.js-next-project-header'), {
                duration: 0.4,
                width: '100%',
                ease: Expo.easeInOut,
            }, 'start+=0.4');
        } else {
            tl.to($(_this.oldContainer).find('.js-next-project-header'), {
                duration: 0.4,
                height: '100%',
                ease: Expo.easeInOut,
            }, 'start');
        }
    });
}

function nextCaseFadeInNextCase(_this) {
    /**
     * _this.newContainer is the HTMLElement of the new Container
     * At _this stage _this.newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
     * Please note, _this.newContainer is available just after _this.newContainerLoading is resolved!
     */

    let initColor = $(_this.newContainer).data('init-color');

    if (initColor === undefined) {
        initColor = '#ffffff';
    }

    gsap.set($('.js-page-transitions'), {
        backgroundColor: initColor,
    });

    return new Promise((resolve) => {
        //const stageInElements = $(_this.newContainer).find('.js-stage-in');

        /*gsap.set(stageInElements, {
            autoAlpha: 0,
            y: '50%',
            skewY: '2.5deg',
        });*/

        gsap.set(_this.newContainer, {
            autoAlpha: 1,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 15,
        });

        gsap.to($(_this.newContainer), 0.6, {
            autoAlpha: 1,
            ease: Expo.easeInOut,
            onStart: () => {

                // fix blink old container (homepage -> single work)
                gsap.set(_this.oldContainer, {
                    autoAlpha: 0,
                })
                // fix blink body background-color

                gsap.set($('html, body'), {
                    scrollTo: {
                        y: 0,
                        x: 0,
                    },
                });

                /*gsap.to(stageInElements, 0.6, {
                    autoAlpha: 1,
                    y: '0%',
                    skewY: '0deg',
                    ease: Expo.easeOut,
                    stagger: 0.1
                });*/
            },
            onComplete: () => {
                gsap.set(_this.newContainer, {
                    clearProps: 'all',
                });

                resolve('NEXT CASE - fadeIn() is resolved -> EVERYTHING IS DONE!');
                _this.done();

                // UNLOCK HTML
                //$('html').removeClass('is-locked');
                $('html').removeClass('is-next-case-loading');
            },
        });
    });
}

export { nextCaseScrollCaseToTop, nextCaseFadeInNextCase }
