export default class ContentSlider {
    constructor() {
        const _defaults = {
            slider: '.js-content-swiper',
            sliderPrevious: '.js-content-slider-previous',
            sliderNext: '.js-content-slider-next',
        };

        this.defaults = Object.assign({}, _defaults);

        if (this.getSlider().length > 0) {
            this.initSliders();
        }
    }

    init() {

    }

    // region Getters
    getSlider() {
        return document.querySelectorAll(this.defaults.slider);
    }
    // endregion

    // region Slider

    initSliders() {
        for (let slider of this.getSlider()) {
            const prev = slider.querySelector(this.defaults.sliderPrevious);
            const next = slider.querySelector(this.defaults.sliderNext);

            const swiper = new Swiper(slider, {
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },
                speed: 800,
                navigation: {
                    prevEl: prev,
                    nextEl: next,
                },
            });
        }
    }

    // endregion
}
