import { TweenMax, TimelineMax, Expo, Power0 } from 'gsap'
import ScrollMagic from "scrollmagic";
//import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
//ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

import addChameleonJsToJquery from 'jquery.chameleon.js/addChameleonJsToJquery';
addChameleonJsToJquery($)

export default class ChangeColor {
    /**
     *
     */
    constructor() {
        this.colorChangeControllers = {};
        this.colorChangeScenes = {};
        this.defaults = {
            initialColorEl: '.js-init-color',
            colorEl: '.js-change-color',

            // COLOR EXTRACT
            imageWrapper: '.js-image-wrapper',
            image: '.js-image',
            imageMask: '.js-image-mask',
        };
    }

    // region Init

    init() {
        //console.log('ChangeColor init()');
    }

    // endregion

    // region Getters

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    getInitialColorEl() {
        return $(this.configuration.initialColorEl);
    }

    getColorEl() {
        return $(this.configuration.colorEl);
    }

    getImageWrapper() {
        return $(this.configuration.imageWrapper);
    }

    getImage() {
        return $(this.configuration.image);
    }

    getImageMask() {
        return $(this.configuration.imageMask);
    }

    // endregion

    // region Methods
    /**
     *
     * @param options
     */
    attach(options = {}) {
        this.configuration = Object.assign({}, this.defaults, options);

        if (this.getInitialColorEl().length > 0) {
            this.init();
            this.setInitialColor();
            this.initChangeColor();
        }

        if (this.getImage().length > 0) {
            this.initExtractColor();
        }

        this.changeColorTl = null;
    }

    destroy() {
        if (!this.configuration) {
            return;
        }

        this.getColorEl().each((index, elem) => {

            if (this.colorChangeControllers[index]) {
                this.colorChangeControllers[index].destroy();
                this.colorChangeControllers[index] = null;
            }
            if (this.colorChangeScenes[index]) {
                this.colorChangeScenes[index].destroy();
                this.colorChangeScenes[index] = null;
            }
        });
    }

    setInitialColor() {

        let initColor = this.getInitialColorEl().data('init-color');

        if (initColor === undefined) {
            initColor = '#ffffff';
        }

        TweenMax.set($('body'), {
            backgroundColor: initColor,
            delay: 0.2,
        });
    }

    initChangeColor() {
        // SCROLLMAGIC ANIMATE

        this.getColorEl().each((index, elem) => {
            const $el = $(elem);
            this.colorChangeControllers[index] = new ScrollMagic.Controller();
            this.changeColorTl = new TimelineMax();

            this.changeColorTl.to($('body'), {
                duration: 0.1,
                backgroundColor: `${$el.data('change-color')}`,
                ease: Power0.easeNone,
            });

            // build scene
            this.colorChangeScenes[index] = new ScrollMagic.Scene({
                triggerElement: elem,
                duration: '50%',
            })
                .setTween(this.changeColorTl)
                .addTo(this.colorChangeControllers[index]);
                // .addIndicators({
                //     name: 'indicator ' + index,
                // });
            this.colorChangeScenes[index].reverse(true);
        });
    }

    initExtractColor() {

        $('.js-image').chameleon("getImageColors", {
            sort_type: "disabled",
            color_format: "hex",
            img_src: "",
            color_alpha: 150,
            color_difference: 120,
            canvas_side: 400,
            debug: false,
            onGetColorsSuccess: (colors, $container) => {
                const $colors = jQuery.fn.chameleon('wrapColor', colors);

                TweenMax.set($container.parent().find('.js-image-mask'), {
                    backgroundColor: `${$colors[3].innerText}`,
                });

                $container.removeClass('loading').addClass('colors-extracteddone').siblings().removeClass('done');
            },
            onGetColorsError(colors, $container, s, img_src) {
                console.error('Error occurred on getImageColors!', img_src);
            },
        });

        this.animateImageMask();
    }

    animateImageMask() {
        for (const imageWrapper of $('.js-image-wrapper')) {
            const controller = new ScrollMagic.Controller();

            const maskTl = new TimelineMax();

            const image = $(imageWrapper).find('.js-image');
            const imageMask = $(imageWrapper).find('.js-image-mask');

            let animationTrigger = $(imageWrapper).data('animation-trigger');

            let animationDelay = $(imageWrapper).data('animation-delay');

            if (animationDelay === undefined) {
                animationDelay = 0;
            }

            if (animationTrigger === undefined) {
                animationTrigger = 0.9;
            }

            maskTl.add('start')
                .to(imageMask, {
                    duration: 1,
                    y: '-100%',
                    delay: animationDelay,
                    ease: Expo.easeInOut,
                }, 'start')
                .fromTo(image, 1.2, {
                    autoAlpha: 0,
                    y: 30,
                }, {
                    autoAlpha: 1,
                    y: 0,
                    ease: Expo.easeInOut,
                }, 'start');

            const scene = new ScrollMagic.Scene({
                triggerElement: imageWrapper,
                triggerHook: animationTrigger,
            })
                .setTween(maskTl)
                .addTo(controller);

            scene.reverse(true);
        }
    }

    // endregion
}
