import { gsap, TweenMax, TimelineMax, Power0 } from 'gsap'
import ScrollMagic from "scrollmagic";
//import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
//ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

import addChameleonJsToJquery from 'jquery.chameleon.js/addChameleonJsToJquery';
addChameleonJsToJquery($)

export default class ChangeColor {
    /**
     *
     */
    constructor() {
        this.svgHandControllers = {};
        this.svgHandScenes = {};
        this.defaults = {
            svgHand: '.js-shake-hand',
        };
    }

    // region Init

    init() {
        //console.log('ChangeColor init()');
    }

    // endregion

    // region Getters

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    getSvgHand() {
        return $(this.configuration.svgHand);
    }

    // endregion

    // region Methods
    /**
     *
     * @param options
     */
    attach(options = {}) {
        this.configuration = Object.assign({}, this.defaults, options);

        if (this.getSvgHand().length > 0) {
            this.init();
            this.initSvgHandShaking();
        }

        this.svgHandShakeTl = null;
    }

    destroy() {
        if (!this.configuration) {
            return;
        }

        this.getSvgHand().each((index, elem) => {

            if (this.svgHandControllers[index]) {
                this.svgHandControllers[index].destroy();
                this.svgHandControllers[index] = null;
            }
            if (this.svgHandScenes[index]) {
                this.svgHandScenes[index].destroy();
                this.svgHandScenes[index] = null;
            }
        });
    }

    initSvgHandShaking() {
        // SCROLLMAGIC ANIMATE

        this.getSvgHand().each((index, elem) => {
            const $el = $(elem);
            this.svgHandControllers[index] = new ScrollMagic.Controller();
            this.svgHandShakeTl = gsap.timeline();

            this.svgHandShakeTl.to(this.getSvgHand(), {
                onComplete: () => {
                    this.getSvgHand().addClass('is-visible');
                },
                ease: Power0.easeNone,
            }, '=1.5');

            // build scene
            this.svgHandScenes[index] = new ScrollMagic.Scene({
                triggerElement: elem,
                //duration: '-50%',
            })
                .setTween(this.svgHandShakeTl)
                .addTo(this.svgHandControllers[index]);

            this.svgHandScenes[index].reverse(true);
        });
    }
    // endregion
}
