import { TweenMax, TimelineMax } from 'gsap'
import ScrollMagic from "scrollmagic";
//import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
//ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export default class Parallax {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        this.parallaxControllers = {};
        this.parallaxScenes = {};

        this.defaults = {
            parallaxEl: '[data-parallax]',
        };
    }

    // region getters
    getParallaxEl() {
        return $(this.configuration.parallaxEl);
    }
    // endregion

    // region Methods

    attach(options = {}) {
        this.configuration = Object.assign({}, this.defaults, options);

        if (this.getParallaxEl().length > 0 && $('body').hasClass('is-desktop')) {
            this.init();
        }
    }

    destroy() {
        if (!this.configuration) {
            return;
        }

        this.getParallaxEl().each((index, elem) => {
            if (this.parallaxControllers[index]) {
                this.parallaxControllers[index].destroy();
                this.parallaxControllers[index] = null;
            }
            if (this.parallaxScenes[index]) {
                this.parallaxScenes[index].destroy();
                this.parallaxScenes[index] = null;
            }
        });
    }

    init() {
        //console.log('Parallax init()');

        // SET ANIMATION
        this.getParallaxEl().each((index, elem) => {
            const $el = $(elem);

            let parallaxSpeed = $el.data('parallax-speed');

            if (parallaxSpeed === undefined) {
                parallaxSpeed = 1;
            }

            gsap.set($el, {
                y: `${parallaxSpeed * 10}%`,
            });

            this.parallaxControllers[index] = new ScrollMagic.Controller();

            const tween = gsap.timeline();

            tween.to(elem, {
                duration: 1,
                y: `${parallaxSpeed * -10}%`,
            });

            // build scene
            this.parallaxScenes[index] = new ScrollMagic.Scene({
                triggerElement: elem,
                triggerHook: 1,
                duration: '200%',
            })
                .setTween(tween)
                // .addIndicators() // add indicators (requires plugin)
                .addTo(this.parallaxControllers[index]);
            this.parallaxScenes[index].reverse(true);
        });
    }

    // endregion
}
