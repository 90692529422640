import { TweenMax, TimelineMax, Power3, Power4 } from 'gsap'
import * as PIXI from 'pixi.js'
//import { PixiPlugin } from "gsap/PixiPlugin";
//gsap.registerPlugin(PixiPlugin);

export default class WorkList {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            workItem: '.js-work-item',
            workItemPreviewList: '.js-work-preview-list',
            workItemImg: '.js-work-preview',

            //
            activeItemClass: 'is-active',

            //
            workCanvas: '.js-work-preview-canvas',

            //
            visuallyHidden: '.js-visually-hidden',

            //
            workLinks: '.js-work-links',
        };

        this.defaults = Object.assign({}, _defaults, options);

        this.init();
    }

    // region Getters

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    getWorkItem() {
        return $(this.defaults.workItem);
    }

    getWorkItemImg() {
        return $(this.defaults.workItemImg);
    }

    getWorkItemPreviewList() {
        return $(this.defaults.workItemPreviewList);
    }

    getWorkCanvas() {
        return $(this.defaults.workCanvas);
    }

    getWorkLinks() {
        return $(this.defaults.workLinks);
    }

    getCanvasEl() {
        return $(this.defaults.workCanvas).find('canvas');
    }

    // endregion

    init() {
        if (this.getWorkItem().length > 0) {

            // CANVAS DIMENSIONS
            this.canvasWidth = this.getWorkItemImg().innerWidth() || (720 * 1.3);
            this.canvasHeight = this.getWorkItemImg().innerHeight() || (405 * 1.3);

            // console.log(this.canvasWidth);
            // console.log(this.canvasHeight);

            this.workItemHover(this.getWorkItem());
            this.workHover(this.getWorkItemPreviewList());
            //this.killOnScroll();

            this.displacementMap = $(this.getWorkItemPreviewList()).find(this.defaults.visuallyHidden).attr('src');
            //this.displacementMap = this.getRandomDisplacementMap();

            //console.log(this.displacementMap);
            this.initWorkCanvas();

            //
            //console.log(this.getCanvasEl());
        }
    }

    getRandomDisplacementMap() {
        const arrayImages = $(this.getWorkItemPreviewList()).find(this.defaults.visuallyHidden).data('src').split(',');
        return arrayImages[Math.floor(Math.random() * arrayImages.length)];
    }

    /** s
     *
     * @param workItem
     */
    workItemHover(workItem) {
        // console.log(`workItemHover(${workItem})`);

        workItem.on('mouseenter', (ev) => {
            ev.preventDefault();

            const workItemId = $(ev.currentTarget).data('work-preview-id');

            this.workHoverEnter(workItemId);
        });

        workItem.on('mouseleave', () => {
            this.workHoverLeave();
        });
    }

    killOnScroll() {

        // console.log('killOnScroll()');

        const workLinksOffset = this.getWorkLinks().offset().top - 300;

        $(window).on('scroll', (ev) => {
            const currentScroll = $(ev.currentTarget).scrollTop();

            if (currentScroll > workLinksOffset) {
                this.workHoverLeave();
            }
        });
    }

    /**
     *
     * @param workContainer
     */
    workHover(workContainer) {

        // console.log(`workHover(${workContainer})`);

        $(document).on('mousemove', (ev) => {
            const decimalX = (ev.clientX / window.innerWidth) - 0.5;
            const decimalY = (ev.clientY / window.innerHeight) - 0.5;

            TweenMax.to(workContainer, {
                duration: 0.4,
                x: 180 * decimalX,
                y: 90 * decimalY,
                ease: Power3.easeOut,
            });
        });

        // SETTINGS
        const workItem = $(this.defaults.workItem);
        const hoverDuration = 0.4;
        const opacityLevel = 0.3;


        // CONTEXT SHIFTING
        // mouseenter
        $(document).on('mouseenter', this.defaults.workItem, (ev) => {
            ev.preventDefault();

            const notItem = workItem.not(ev.currentTarget);

            TweenMax.to(ev.currentTarget, hoverDuration, {
                opacity: 1,
                ease: Power3.easeOut,
            });

            TweenMax.to(notItem, hoverDuration, {
                opacity: opacityLevel,
                x: 0,
                ease: Power3.easeOut,
            });
        });

        // mouseleave
        $(document).on('mouseleave', this.defaults.workItem, (ev) => {
            ev.preventDefault();

            const notItem = workItem.not(ev.currentTarget);

            TweenMax.to([ev.currentTarget, notItem], hoverDuration, {
                opacity: 1,
                ease: Power3.easeOut,
            });
        });
    }

    initWorkCanvas() {

        //console.log('initWorkCanvas()');

        // CREATE PIXI APPLICATION
        const app = new PIXI.Application(
            this.canvasWidth,
            this.canvasHeight, {
                transparent: true,
            },
        );

        // APPEND CANVAS
        this.getWorkCanvas().append(app.view);

        // CREATE SLIDES CONTAINER
        this.slidesContainer = new PIXI.Container();
        app.stage.addChild(this.slidesContainer);


        // CREATE DISPLACEMENT MAP
        const displacementMap = PIXI.Sprite.fromImage(this.displacementMap);

        // CREATE FILTER
        const filter = new PIXI.filters.DisplacementFilter(displacementMap);

        displacementMap.name = 'displacementMap';
        displacementMap.anchor.set(0.5);
        displacementMap.scale.set(1);
        displacementMap.position.set(this.canvasWidth / 2, this.canvasHeight / 2);

        app.stage.filterArea = app.screen;
        app.stage.filters = [filter];
        app.stage.addChild(displacementMap);


        // PIXI SPRITE ARRAY
        for (const spriteImage of this.getWorkItemImg()) {
            // const texture = new PIXI.Texture.fromImage($(spriteImage).data('work-preview'));
            const texture = new PIXI.Texture.fromImage($(spriteImage).find(this.defaults.visuallyHidden).attr('src'));
            const image = new PIXI.Sprite(texture);

            image.name = `workPreview`;
            image.interactive = false;
            image.alpha = 0;
            image.height = this.canvasHeight;
            image.width = this.canvasWidth;

            this.slidesContainer.addChild(image);
        }

        // DISPLACE TIMELINE
        this.displaceTl = new TimelineMax({
            paused: true,
        });

        this.displaceTl.add('start')
            .fromTo(this.getCanvasEl(), 0.4, {
                autoAlpha: 0,
            }, {
                autoAlpha: 1,
                ease: Power4.easeOut,
            }, "start")
            .fromTo(this.getCanvasEl(), 0.8, {
                scale: 1.25,
            }, {
                scale: 1,
                ease: Power4.easeOut,
            }, "start")
            .fromTo(
                filter.scale, 1, {
                    x: 150,
                    y: 50,
                },
                {
                    x: 0,
                    y: 0,
                    ease: Power4.easeOut,
                    onComplete: () => {

                    },
                }, "start",
            );

        return [this.slidesContainer, this.displaceTl];
    }

    workHoverEnter(layerId) {

        //console.log(`workHoverEnter(${layerId})`);

        const workColor = $('.js-work-item[data-work-preview-id="'+layerId+'"]').closest('.js-work-link').data('work-color');

        // SET ALPHA OF HOVERED CASE PREVIEW
        TweenMax.to(this.slidesContainer.children[layerId], {
            duration: 0.4,
            alpha: 1,
            ease: Power3.easeOut,
            onStart: () => {
                TweenMax.to(this.getCanvasEl(), {
                    duration: 0.4,
                    backgroundColor: workColor,
                    ease: Power3.easeOut,
                })

                this.displaceTl.progress(0);
                this.displaceTl.play();
            },
        });
    }

    workHoverLeave() {
        //console.log('workHoverLeave()');

        TweenMax.to(this.slidesContainer.children, {
            duration: 0.4,
            alpha: 0,
            ease: Power3.easeOut,
            onStart: () => {
                TweenMax.to(this.getCanvasEl(), {
                    duration:  0.4,
                    backgroundColor: 'transparent',
                    ease: Power3.easeOut,
                })
            }
        });
    }
}
