import Swiper from "swiper";

export default class TestimonialsSlider {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            // CULTURE SLIDER
            slider: '.js-testimonials-slider',
            sliderPrevious: '.js-testimonials-slider-previous',
            sliderNext: '.js-testimonials-slider-next',
            sliderCounter: '.js-testimonials-slider-counter',
        };

        this.defaults = Object.assign({}, _defaults, options);

        if (this.getSlider().length > 0) {
            this.initSlider();
        }
    }

    init() {

    }

    // region Getters

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    getSlider() {
        return $(this.defaults.slider);
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    getSliderPrevious() {
        return $(this.defaults.sliderPrevious);
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    getSliderNext() {
        return $(this.defaults.sliderNext);
    }

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    getSliderCounter() {
        return $(this.defaults.sliderCounter);
    }

    // endregion


    // region Slider

    initSlider() {
        const swiper = new Swiper(this.getSlider(), {
            autoplay: {
                delay: this.getSlider().data('swiper-autoplay-speed'),
                disableOnInteraction: false,
            },
            loop: true,
            speed: this.getSlider().data('swiper-speed'),
            effect: 'fade',
            grabCursor: true,
            autoHeight: this.getSlider().data('swiper-autoheight'),
            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            preloadImages: false,
            // Enable lazy loading
            lazy: true,
            fadeEffect: {
                crossFade: true,
            },
            pagination: {
                el: this.defaults.sliderCounter,
                type: 'custom',
                renderCustom(swiper, current, total) {
                    const i = (current || 0);
                    return (`<i class="counter-number counter-number--current">${(`0${i}`).slice(-2)}</i><span class="counter-separator">/</span><i class="counter-number counter-number--total">${(`0${total}`).slice(-2)}</i>`);
                },
            },
            navigation: {
                prevEl: this.getSliderPrevious(),
                nextEl: this.getSliderNext(),
            },
            on: {
                progress() {

                },
                touchStart() {

                },
                setTransition(speed) {

                },
            },
        });
    }

    // endregion
}
