export default class CtaHover {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            ctaTrigger: '.js-cta-trigger',
            ctaImageContainer: '.js-cta-image-container',
            ctaImage: '.js-cta-image',
        };

        this.defaults = Object.assign({}, _defaults, options);

        if (this.getCtaImageContainer().length > 0) {
            this.init();
            this.initHoverTl();
        }
    }

    init() {
        //console.log('CtaHover init()');
    }

    // region getters

    getCtaTrigger() {
        return $(this.defaults.ctaTrigger);
    }

    getCtaImageContainer() {
        return $(this.defaults.ctaImageContainer);
    }

    getCtaImage() {
        return $(this.defaults.ctaImage);
    }

    // endregion

    // region methods

    initHoverTl() {
        // RANDOMIZE LOADER SLIDES
        const $images = this.getCtaImage();
        // const $images = this.getCtaImage().toArray();
        // $images.sort(() => 0.5 - Math.random());

        const speed = 0.3;

        const hoverTl = new TimelineMax({
            paused: true,
            repeat: -1,
            repeatDelay: speed,
        });

        hoverTl.staggerFromTo($images, 0.1, {
                autoAlpha: 0,
                scale: 1.15,
            }, {
                autoAlpha: 1,
                scale: 1,
                ease: Power0.easeNone,
                cycle: {
                    zIndex(index) {
                        return (index + 2);
                    },
                },
                onStart(tween) {

                },
                onComplete(tween) {

                },
                onStartParams: ["{self}"],
                onCompleteParams: ["{self}"],
            }, speed);


        this.getCtaTrigger().on('mouseenter', () => {
            hoverTl.play();
        });

        this.getCtaTrigger().on('mouseleave', () => {
            hoverTl.pause();
        });
    }

    // endregion
}