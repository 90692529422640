export default class CustomSelect {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            selector: 'select'
        };

        this.defaults = Object.assign({}, _defaults, options);

        this.init();
    }

    getSelector() {
        return $(this.defaults.selector);
    }

    // region init

    init() {
        $('select').not('.is-loaded').each(function() {
            var $select = $(this);
            var $selectParent = $select.closest('.c-fieldset__field-wrapper');
            var $selectOptions = $select.find('option');
            var $selectOptionsSelected = $select.find('option:selected');

            $select.addClass('is-loaded select-hide');

            /* For each element, create a new DIV that will act as the selected item: */
            var $selectSelected = $("<div></div>");
            $selectSelected.addClass("select-selected");
            $selectSelected.html($selectOptionsSelected.html());
            $selectParent.append($selectSelected);

            /* For each element, create a new DIV that will contain the option list: */
            var $divOptionList = $("<div></div>");
            $divOptionList.addClass("select-items select-hide");

            $selectOptions.each(function () {
                var $option = $(this);

                /* For each option in the original select element, create a new DIV that will act as an option item: */
                if ($.trim($option.html()) !== '') {

                    var $divOption = $("<div></div>");
                    $divOption.addClass('select-option');
                    $divOption.html($option.html());
                    $divOptionList.append($divOption);
                }
            });

            $selectParent.append($divOptionList);
        });

        //$('body').on("click", $divOption, function (e) {
        $('.select-option').on("click", function (e) {
            var $clickedOption = $(e.target);
            var $selectParent = $clickedOption.closest('.c-fieldset__field-wrapper');
            var $selectOptions = $selectParent.find('select option');
            var $selectSelected = $selectParent.find('.select-selected');
            var $selectItems = $selectParent.find('.select-item');
            /* When an item is clicked, update the original select box, and the selected item: */
            $selectOptions.removeAttr('selected');

            $selectOptions.each(function (index, element) {
                var $element = $(element);
                if ($element.html() === $clickedOption.html()) {
                    $element.click();
                    $element.attr('selected', true);
                    $selectParent.find('.same-as-selected').removeClass('same-as-selected');
                    $clickedOption.addClass('same-as-selected');
                    $selectSelected.html($clickedOption.html());
                }
            });
        });

        //$('body').on("click", $selectSelected, function (e) {
        $('.select-selected').on("click", function (e) {
            /* When the select box is clicked, close any other select boxes, and open/close the current select box: */
            e.stopPropagation();
            var $this = $(e.target);
            var $thisParent = $this.closest('.c-fieldset__field-wrapper');
            var $thisParentSelectItems = $thisParent.find('.select-items');
            var $thisSelected = $thisParent.find('.select-selected');
            closeAllSelect($this);

            $thisParentSelectItems.toggleClass("select-hide");
            $thisSelected.toggleClass("select-arrow-active");
        });

        function closeAllSelect($elmnt) {
            /* A function that will close all select boxes in the document, except the current select box: */

            var arrNo = [];
            var $selectedItems = $(".select-items");
            var $selectSelected = $(".select-selected");

            $selectSelected.each(function(index, element) {
                if ($(element) === $elmnt) {
                    arrNo.push(index);
                }
            });

            $selectedItems.each(function(index, element) {
                if (arrNo.indexOf(index)) {
                    $(element).addClass("select-hide");
                }
            });
        }

        /* If the user clicks anywhere outside the select box, then close all select boxes: */
        $('body').on("click", closeAllSelect);
    }

    oldInit() {

        var x, i, j, l, ll, selElmnt, a, b, c;
        /* Look for any elements with the class "custom-select": */

        x = document.getElementsByTagName(this.defaults.selector);
        //x = document.getElementsByClassName(this.defaults.selector);

        l = x.length;
        for (i = 0; i < l; i++) {
            //selElmnt = x[i].getElementsByTagName("select")[0];
            selElmnt = x[i];
            ll = selElmnt.length;
            /* For each element, create a new DIV that will act as the selected item: */
            a = document.createElement("DIV");
            a.setAttribute("class", "select-selected");
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);
            /* For each element, create a new DIV that will contain the option list: */
            b = document.createElement("DIV");
            b.setAttribute("class", "select-items select-hide");
            for (j = 1; j < ll; j++) {
                /* For each option in the original select element,
                create a new DIV that will act as an option item: */
                c = document.createElement("DIV");
                c.innerHTML = selElmnt.options[j].innerHTML;
                c.addEventListener("click", function (e) {
                    /* When an item is clicked, update the original select box,
                    and the selected item: */
                    var y, i, k, s, h, sl, yl;
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                    sl = s.length;
                    h = this.parentNode.previousSibling;
                    for (i = 0; i < sl; i++) {
                        if (s.options[i].innerHTML == this.innerHTML) {
                            s.selectedIndex = i;
                            h.innerHTML = this.innerHTML;
                            y = this.parentNode.getElementsByClassName("same-as-selected");
                            yl = y.length;
                            for (k = 0; k < yl; k++) {
                                y[k].removeAttribute("class");
                            }
                            this.setAttribute("class", "same-as-selected");
                            break;
                        }
                    }
                    h.click();
                });
                b.appendChild(c);
            }
            x[i].appendChild(b);
            a.addEventListener("click", function (e) {
                /* When the select box is clicked, close any other select boxes,
                and open/close the current select box: */
                e.stopPropagation();
                closeAllSelect(this);
                this.nextSibling.classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");
            });
        }

        function closeAllSelect(elmnt) {
            /* A function that will close all select boxes in the document,
            except the current select box: */
            var x, y, i, xl, yl, arrNo = [];
            x = document.getElementsByClassName("select-items");
            y = document.getElementsByClassName("select-selected");
            xl = x.length;
            yl = y.length;
            for (i = 0; i < yl; i++) {
                if (elmnt == y[i]) {
                    arrNo.push(i)
                } else {
                    y[i].classList.remove("select-arrow-active");
                }
            }
            for (i = 0; i < xl; i++) {
                if (arrNo.indexOf(i)) {
                    x[i].classList.add("select-hide");
                }
            }
        }

        /* If the user clicks anywhere outside the select box,
        then close all select boxes: */
        document.addEventListener("click", closeAllSelect);
    }
}
