import ImagesAnimation from "../../ImagesAnimation";
import Footer from "../../Footer";
import ChangeColor from "../../ChangeColor";
import ExitEnter from "../../ExitEnter";
import Navigation from "../../Navigation";
import OnScrollAnimations from "../../OnScrollAnimations";
import LazyLoad from "vanilla-lazyload";
import Counter from "../../Counter";
import SliderBlock from "../../SliderBlock";
import ReadMoreBlock from "../../ReadMoreBlock";
import Accordion from "../../Accordion";
import ContentSlider from "../../ContentSlider";
import CtaHover from "../../CtaHover";
import TestimonialsSlider from "../../TestimonialsSlider";
import ClipNavigation from "../../ClipNavigation";
import Parallax from "../../Parallax";
import MorphingButton from "../../MorphingButton";
import Forms from "../../Forms";
import ContactForm from "../../ContactForm";

const morphingButton = new MorphingButton();
const colorChange = new ChangeColor();
const exitEnter = new ExitEnter();
const navigation = new Navigation();
const onScrollAnimations = new OnScrollAnimations();
const clipNavigation = new ClipNavigation();
const parallax = new Parallax();
const forms = new Forms();
const contactForm = new ContactForm();

const lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy-load"
});

export default class initBarbaService {

    constructor() {
        this.page = this.extend();
        this.page.init();
    }

    extend() {
        return Barba.BaseView.extend({
            namespace: 'service',
            onEnter() {
                console.log('service onEnter');

                colorChange.destroy();
                //onScrollAnimations.destroy();
                parallax.destroy();
                navigation.init();
                clipNavigation.clipNavigation();

                // clipNavigation.getClipElement().midnight('refresh');
                // clipNavigation.getClipElementMenu().midnight('refresh');

                // new Counter();
                // new SliderBlock();
                // new ReadMoreBlock();
                // new Accordion();
                // new ContentSlider();

                // lazy load update on page change
                lazyLoadInstance.update();
            },
            onEnterCompleted() {
                console.log('service onEnterCompleted');

                colorChange.attach();
                //onScrollAnimations.attach();
                parallax.attach();
                //sendPageView();

                morphingButton.init();
                forms.init();
                contactForm.init();

                // new ImagesAnimation();
                // new CtaHover();
                // new Footer();
                // new TestimonialsSlider();
                //
                //navigation.fixActiveElement();
            },
            onLeave() {
                exitEnter.exit();
            },
            onLeaveCompleted() {

            },
        });
    }
}
