import { midnight } from '../bundles/midnight.jquery'

export default class ClipNavigation {
    /**
     *
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            clipElement: '.js-clip-element',
            clipElementMenu: '.js-clip-element-menu',
        };

        this.defaults = Object.assign({}, _defaults, options);


        this.init();
        this.clipNavigation();
    }

    init() {
        //console.log('ClipNavigation init()');
    }

    // region getters

    getClipElement() {
        return $(this.defaults.clipElement);
    }

    getClipElementMenu() {
        return $(this.defaults.clipElementMenu);
    }

    // endregion

    // region methods

    clipNavigation() {
        this.getClipElement().midnight({
            headerClass: 'clip-header',
            innerClass: 'clip-inner',
            defaultClass: 'is-colored',
            sectionSelector: 'navigation-logo-color',
        });

        this.getClipElementMenu().midnight({
            headerClass: 'clip-header',
            innerClass: 'clip-inner',
            defaultClass: 'is-black',
            sectionSelector: 'navigation-color',
            headerStartOffsetSelector: 'navigation-header-offset-start',
            headerOffsetEndSelector: 'navigation-header-offset-end',
            sectionOffsetStartSelector: 'navigation-section-offset-start',
            sectionOffsetEndSelector: 'navigation-section-offset-end',
        });

        // setInterval(() => {
        //     console.log('nannana');
        //     this.getClipElement().midnight('refresh');
        // }, 1000);
    }

    // endregion
}
