import ChangeColor from "../../ChangeColor";
import ExitEnter from "../../ExitEnter";
import Navigation from "../../Navigation";
import OnScrollAnimations from "../../OnScrollAnimations";
import LazyLoad from "vanilla-lazyload";
import Forms from "../../Forms";
import ContactForm from "../../ContactForm";
//import Cursor from "../../Cursor";
import MorphingButton from "../../MorphingButton";
import ClipNavigation from "../../ClipNavigation";
//import HideOnScrollDown from "../../HideOnScrollDown";

const morphingButton = new MorphingButton();
const colorChange = new ChangeColor();
const exitEnter = new ExitEnter();
const navigation = new Navigation();
const onScrollAnimations = new OnScrollAnimations();
//const cursor = new Cursor();
const forms = new Forms();
const contactForm = new ContactForm();
const clipNavigation = new ClipNavigation();
//const hideOnScrollDown = new HideOnScrollDown();

const lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy-load"
});

export default class initBarbaContact {

    constructor() {
        this.page = this.extend();
        this.page.init();
    }

    extend() {
        return Barba.BaseView.extend({
            namespace: 'contact',
            onEnter() {
                // console.log('contact onEnter');

                colorChange.destroy();
                onScrollAnimations.destroy();
                navigation.init();
                clipNavigation.clipNavigation();

                // lazy load update on page change
                lazyLoadInstance.update();

                $('.js-contact-form-container').removeClass('u-visually-hidden')
            },
            onEnterCompleted() {
                // console.log('contact onEnterCompleted');

                morphingButton.init();
                colorChange.attach();
                onScrollAnimations.attach();
                //cursor.init();
                forms.init();
                contactForm.init();
                //hideOnScrollDown.init();
                //sendPageView();
            },
            onLeave() {
                exitEnter.exit();
            },
            onLeaveCompleted() {

            },
        });
    }
}
