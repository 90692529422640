import { midnight } from '../bundles/midnight.jquery'
import { KeyCodes } from '../utils/KeyCodes'
import { gsap, TweenMax, TimelineMax, Expo, Power0, Power3 } from 'gsap'
import ScrollMagic from "scrollmagic";
//import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
//ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

import * as PIXI from 'pixi.js'

export default class Navigation {
    /**
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            navigation: '.js-navigation',
            navigationLogo: '.js-navigation-logo',
            navigationTrigger: '.js-navigation-trigger',
            navigationItem: '.js-navigation-item',
            navigationLink: '.js-navigation-link',
            navigationContactInfo: '.js-navigation-contact-info',
            clipElement: '.js-clip-element',
            menu: '.js-menu',
            menuTrigger: '.js-menu-trigger',

            //
            menuStage: '.js-menu-stage',

            //
            menuBg: '.js-menu-bg',
            menuBgCover: '.js-menu-bg-cover',
            menuBgList: '.js-menu-bg-list',
            menuCanvas: '.js-menu-bg-canvas',
            menuBgImg: '.js-menu-bg-image',

            //
            inpageNavigation: '.js-inpage-navigation',
            inpageNavigationVisibility: '.js-inpage-navigation-visible',
            inpageLink: '.js-inpage-link',

            //
            secondaryNavigationCta: '.js-secondary-nav-cta',

            //
            barbaContainer: '.barba-container',
        };

        this.transitionDuration = 0.8;

        this.defaults = Object.assign({}, _defaults, options);

        this.bgIndex = null;


        // this.number = 0;
        // this.raf = null;
    }

    init() {
        if (this.getMenu().length > 0 && this.getMenuTrigger().length > 0) {
            this.initClicks();
            this.initEscButton();
            this.clipNavigation();
            this.initSecondaryNavigationCtaHover();
        }

        if (this.getMenuBg().length > 0) {
            //this.initMenuCanvas();
        }

        if (this.getInpageNavigation().length > 0) {
            this.initScrollIt();
            this.toggleInpageNavigation();
        }
    }

    // region Init

    initClicks() {
        this.getMenuTrigger().on('click', (ev) => {
            ev.preventDefault();
            ev.currentTarget.classList.contains('is-opened') ? this.closeMenu() : this.openMenu();
        });

        this.getNavigationLink().on('click', (ev) => {

            if ($(ev.currentTarget).hasClass('is-active')) {
                ev.preventDefault();

                this.closeMenu();
            }
            else {

                this.closeMenu();

                gsap.set($('body'), {
                    backgroundColor: '#ffffff',
                });
            }
        });
    }

    initEscButton() {
        const _this = this;

        $(document).keyup((ev) => {
            if ((ev.keyCode === KeyCodes.ESCAPE) && (_this.getMenuTrigger().hasClass('is-opened'))) {
                _this.closeMenu();
            }
        });
    }

    fixActiveElement() {
        this.getNavigationLink().removeClass('is-active');
        const item = this.getBarbaContainer().attr('data-menu-item');
        $(`[data-item='${item}']`).addClass('is-active');
    }

    // endregion


    // region Getters

    /**
     * @returns {*|jQuery|HTMLElement}
     */
    getNavigation() {
        return $(this.defaults.navigation);
    }

    getNavigationLogo() {
        return $(this.defaults.navigationLogo);
    }

    getNavigationTrigger() {
        return $(this.defaults.navigationTrigger);
    }

    getNavigationItem() {
        return $(this.defaults.navigationItem);
    }

    getNavigationLink() {
        return $(this.defaults.navigationLink);
    }

    getNavigationContactInfo() {
        return $(this.defaults.navigationContactInfo);
    }

    getClipElement() {
        return $(this.defaults.clipElement);
    }

    getMenu() {
        return $(this.defaults.menu);
    }

    getMenuStage() {
        return $(this.defaults.menuStage);
    }

    getMenuTrigger() {
        return $(this.defaults.menuTrigger);
    }

    getMenuBg() {
        return $(this.defaults.menuBg);
    }

    getMenuBgCover() {
        return $(this.defaults.menuBgCover);
    }

    getMenuBgList() {
        return $(this.defaults.menuBgList);
    }

    getMenuBgImg() {
        return $(this.defaults.menuBgImg);
    }

    // getMenuCanvas() {
    //     return $(this.defaults.menuCanvas);
    // }

    getMenuCanvas() {
        return document.querySelector(this.defaults.menuCanvas);
    }

    getMenuCanvasEl() {
        return $(this.defaults.menuCanvas).find('canvas');
    }

    getInpageNavigation() {
        return $(this.defaults.inpageNavigation);
    }

    getInpageVisibiltyContainer() {
        return $(this.defaults.inpageNavigationVisibility);
    }

    getSecondaryNavigationCta() {
        return $(this.defaults.secondaryNavigationCta);
    }

    getBarbaContainer() {
        return $(this.defaults.barbaContainer);
    }

    // endregion


    // region Menu

    openMenu() {
        const menuOpenTl = gsap.timeline({
            onStart: () => {
                gsap.to(this.getMenuStage(), {
                    duration: 0.3,
                    stagger:  0.05,
                    autoAlpha: 0,
                    ease: Power0.easeNone,
                });

                // gsap.to($('.js-page-wrapper'), {
                //     duration: 1,
                //     x: 60,
                //     ease: Expo.easeInOut,
                // });
            },
        });

        menuOpenTl.add('start')
            .fromTo(this.getMenu(), 0.6, {
                x: "100%",
            }, {
                x: "0%",
                ease: Expo.easeInOut,
                onStart: () => {
                    this.getMenuTrigger().addClass('is-disabled');
                },
                onComplete: () => {
                    $('body').addClass('is-menu-opened');
                    this.getMenuTrigger().removeClass('is-disabled');
                    this.getNavigation().addClass('is-opened');
                    this.getMenuTrigger().addClass('is-opened');
                    //this.getMenuTrigger().attr('data-cursor', 'Close Menu');
                },
            })
            .to(this.getMenuBgCover(), 0.8, {
                x: '-100%', //100
                ease: Expo.easeInOut,
                delay: 0.6,
            }, 'start')
            .fromTo(this.getNavigationLogo(), 0.3, {
                autoAlpha: 0,
                y: '50%',
                skewY: '-2.5deg',
            }, {
                autoAlpha: 1,
                y: '0%',
                skewY: '0deg',
                ease: Expo.EaseOut,
            }, 'start, -=0.7')
            .fromTo(this.getNavigationTrigger(), 0.3, {
                autoAlpha: 0,
                y: '50%',
                skewY: '-2.5deg',
            }, {
                autoAlpha: 1,
                y: '0%',
                skewY: '0deg',
                ease: Expo.EaseOut,
            }, 'start, -=0.7')
            .staggerFromTo(this.getNavigationItem(), 0.3, {
                autoAlpha: 0,
                y: '50%',
                skewY: '-2.5deg',
            }, {
                autoAlpha: 1,
                y: '0%',
                skewY: '0deg',
                ease: Expo.EaseOut,
            }, 0.05, 'start, -=0.7')
            .staggerFromTo(this.getNavigationContactInfo(), 0.2, {
                autoAlpha: 0,
                y: '25%',
                skewY: '-1.5deg',
                ease: Expo.easeInOut,
            }, {
                autoAlpha: 1,
                y: '0%',
                skewY: '0deg',
                ease: Expo.EaseOut,
            }, 0.05, 'start, -=0.4');

        const bgNumber = this.getMenuBgImg().length;
        const randomBgNumber = (Math.floor(Math.random() * (bgNumber - 1 + 1)) + 1) - 1;

        // ANIMATE MENU BG
        if (this.getMenuBg().length > 0) {
            //this.animateMenuBg(randomBgNumber);
        }

        // this.repaint();
    }

    closeMenu() {
        // this.cancelRepaint(this.raf);

        const closeMenuTl = gsap.timeline({
            onStart: () => {
                this.getMenuTrigger().addClass('is-disabled');
                $('body').removeClass('is-menu-opened');
                this.getMenuTrigger().removeClass('is-opened');
                //this.getMenuTrigger().attr('data-cursor', 'Open Menu');

                // gsap.to($('.js-page-wrapper'), {
                //     duration: 0.6,
                //     x: 0,
                //     ease: Expo.easeInOut,
                //     onComplete: () => {
                //         gsap.set($('.js-page-wrapper'), {
                //             clearProps: 'all',
                //         });
                //     },
                // });
            },
            onComplete: () => {
                this.getMenuTrigger().removeClass('is-disabled');
            },
        });

        closeMenuTl.add('start')
            .fromTo(this.getMenuBgCover(), 0.6, {
                x: '-100%', //-100
            }, {
                x: '0%', //0
                ease: Expo.easeInOut,
                onComplete: () => {
                    gsap.to(this.getMenuStage(), {
                        duration: 0.4,
                        autoAlpha: 1,
                        delay: 0.4,
                        ease: Power0.easeNone,
                        onComplete: () => {
                            gsap.set(this.getMenuStage(), {
                                clearProps: 'all',
                            });
                        },
                    });
                },
            }, 'start')
            .staggerFromTo([this.getNavigationLogo(), this.getNavigationTrigger(), this.getNavigationItem(), this.getNavigationContactInfo()], 0.2, {
                autoAlpha: 1,
                y: '0%',
            }, {
                autoAlpha: 0,
                y: '-50%',
                ease: Expo.easeIn,
            }, -0.1, 'start')
            .add('afterStart')
            .to(this.getMenu(), 0.4, {
                autoAlpha: 0,
                onComplete: () => {

                    gsap.set([this.getMenu(), this.getMenuBgCover()], {
                        clearProps: "all",
                    });

                    this.getNavigation().removeClass('is-opened');
                },
            }, 'afterStart');
    }

    // endregion

    // region clip navigation

    clipNavigation() {
        this.getClipElement().midnight({
            headerClass: 'clip-header',
            innerClass: 'clip-inner',
            defaultClass: 'is-colored',
            sectionSelector: 'navigation-logo-color',
        });

        setTimeout(() => {

        }, 4000);
    }

    // endregion

    // region Menu animations

    initMenuCanvas() {
        // CANVAS DIMENSIONS
        const canvasWidth = $(this.defaults.menuCanvas).innerWidth();
        const canvasHeight = $(this.defaults.menuCanvas).innerHeight();

        // CREATE PIXI APPLICATION
        const app = new PIXI.Application(
            canvasWidth,
            canvasHeight, {
                transparent: true,
            },
        );


        // APPEND CANVAS
        this.getMenuCanvas().appendChild(app.view);

        // CREATE SLIDES CONTAINER
        this.slidesContainer = new PIXI.Container();
        app.stage.addChild(this.slidesContainer);

        // CREATE DISPLACEMENT MAP
        const displacementMap = PIXI.Sprite.fromImage($(this.defaults.menuCanvas).data('displacement-map'));

        // CREATE FILTER
        const filter = new PIXI.filters.DisplacementFilter(displacementMap);

        displacementMap.name = 'displacementMap';

        displacementMap.anchor.set(0.5);
        displacementMap.scale.set(2.5);
        displacementMap.position.set(canvasWidth / 2, canvasHeight / 2);

        app.stage.filterArea = app.screen;
        app.stage.filters = [filter];
        app.stage.addChild(displacementMap);

        // PIXI SPRITE ARRAY
        for (const spriteImage of this.getMenuBgImg()) {
            const texture = new PIXI.Texture.fromImage($(spriteImage).data('menu-bg'));
            const image = new PIXI.Sprite(texture);

            image.name = `menuBg`;
            image.interactive = false;
            image.alpha = 1;
            image.width = canvasWidth;
            image.height = canvasHeight;

            this.slidesContainer.addChild(image);
        }

        // DISPLACE TIMELINE
        this.displaceTl = gsap.timeline({
            paused: true,
            onStart: () => {

            },
            onComplete: () => {

            },
        });

        this.displaceTl.add('start')
            .fromTo(this.getMenuCanvas(), 0.2, {
                autoAlpha: 0,
            }, {
                autoAlpha: 1,
                delay: 0.2,
                ease: Expo.easeOut,
            }, 'start')
            .add('afterStart', '+=0.2')
            .fromTo(
                filter.scale, 1.6, {
                    x: 1000,
                    y: 600,
                },
                {
                    x: 1,
                    y: 1,
                    ease: Expo.easeOut,
                    onComplete: () => {

                    },
                }, 'afterStart', '-=0.4',
            );

        return [this.slidesContainer, this.displaceTl];
    }

    animateMenuBg(layerId) {
        gsap.set(this.slidesContainer.children, {
            alpha: 0,
            ease: Power3.easeOut,
        });

        // SET ALPHA
        gsap.to(this.slidesContainer.children[layerId], {
            duration: 0.2,
            alpha: 1,
            delay: 0.2,
            ease: Power3.easeOut,
            onStart: () => {
                this.displaceTl.progress(0);
                this.displaceTl.play();
            },
        });
    }

    // SCROLLIT
    initScrollIt() {
        // SCROLLIT
        $.scrollIt({
            upKey: 38, // key code to navigate to the next section
            downKey: 40, // key code to navigate to the previous section
            easing: 'easeInOutExpo', // the easing function for animation
            scrollTime: 1400, // how long (in ms) the animation takes
            activeClass: 'is-active', // class given to the active nav element
            topOffset: 0, // offste (in px) for fixed top navigation
            // topOffset: $(window).height / 2, // offste (in px) for fixed top navigation
        });
    }

    toggleInpageNavigation() {
        const controller = new ScrollMagic.Controller();
        const scrollDuration = this.getInpageVisibiltyContainer().innerHeight();

        const scene = new ScrollMagic.Scene({
            triggerElement: this.defaults.inpageNavigationVisibility,
            duration: scrollDuration,
        })
            .setClassToggle(this.defaults.inpageNavigation, 'is-visible')
            // .addIndicators({
            //     name: `pin duration: ${scrollDuration}`,
            // })
            .addTo(controller);
    }


    initSecondaryNavigationCtaHover() {
        this.getSecondaryNavigationCta().on('mouseenter', () => {
            this.getSecondaryNavigationCta().addClass('is-hovered');
        });

        this.getSecondaryNavigationCta().on('mouseleave', () => {
            this.getSecondaryNavigationCta().removeClass('is-hovered');
        });
    }

    // endregion

    // region Lock/Unlock HTML

    // lockHTML() {
    //     $('html').addClass('is-locked');
    // }
    //
    // unlockHTML() {
    //     $('html').removeClass('is-locked');
    // }

    // endregion

    // repaint() {
    //     this.raf = requestAnimationFrame(() => {
    //         this.repaint();
    //     });
    //     this.number += 1;
    //     console.log(this.number);
    //     return this.number;
    // }

    // cancelRepaint(raf) {
    //     console.log('end');
    //     cancelAnimationFrame(raf);
    // }

}
