import ExitEnter from './ExitEnter';
import { gsap, TweenMax, TimelineMax, Expo, Power0, Power1, Power4 } from 'gsap';
import SplitText from '../bundles/SplitText'
import WordAnimationEffect from "./WordAnimationEffect";

const exitEnter = new ExitEnter();

// mouse position
var mousePosition = {x:0,y:0};
var position = {x:0,y:0};
var mouseSpeed = 0.35;
// end mouse position

export default class Loader {
    /**
     * @param {object} options
     */
    constructor(options) {
        const _defaults = {
            cursor: '.js-cursor',
            svgHand: '.js-shake-hand',
            mainLoader: '.js-main-loader',
            mainLoaderContainer: '.js-main-loader-container',
            mainLoaderLogo: '.js-main-loader-logo',
            mainLoaderImg: '.js-main-loader-image',
            mainLoaderCurtain: '.js-main-loader-curtain',

            //
            initialColorEl: '.js-init-color',
        };

        this.defaults = Object.assign({}, _defaults, options);

        this.wordAnimationEffect = new WordAnimationEffect();

        // mouse position
        position = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        mousePosition = { x: position.x, y: position.y };
        // end mouse position

        if (this.getMainLoader().length > 0) {


            this.initLoaderSimplified();

            // if(this.getCookie('initLoader')) {
            //     this.initLoaderSimplified();
            // } else {
            //     this.setCookie('initLoader', '1', 1);
            //     this.initLoader();
            // }
        }

        this.loaderTl = null;

        //this.stageHeaderTl = null;

        this.stagePreTitleEl = $('.js-animation-stage-pre-title');
        this.stageSplitTextEl = $('.js-animation-stage-title');
        this.stageEl = $('.js-animation-stage-element');
        this.stageMenuEl = $('.js-menu-stage');

        this.stageWordAnimationTl = new TimelineMax({
            paused: true,
        });

        this.stagePreHeaderTl = new TimelineMax({
            paused: true,
        });

        this.stageHeaderTl = new TimelineMax({
            paused: true,
        });

        this.stageHeaderElTl = new TimelineMax({
            paused: true,
        });

        if (this.stagePreTitleEl.length > 0) {
            const bfSplitText = new SplitText(this.stagePreTitleEl, {
                type: 'lines',
            });

            TweenMax.set(this.stagePreTitleEl, {
                perspective: 400,
            });

            this.stagePreHeaderTl.add('start')
                .staggerFrom(bfSplitText.lines, 1.2, {
                    opacity: 0,
                    y: '75%',
                    skewY: '-0.75deg',
                    ease: Expo.easeOut,
                }, 0.1, 'start');
        }

        if (this.stageSplitTextEl.length > 0) {
            const bfSplitText = new SplitText(this.stageSplitTextEl, {
                type: 'lines',
            });

            TweenMax.set(this.stageSplitTextEl, {
                perspective: 400,
            });

            this.stageHeaderTl.add('start')
                .staggerFrom(bfSplitText.lines, 1.2, {
                    opacity: 0,
                    y: '75%',
                    skewY: '-0.75deg',
                    ease: Expo.easeOut,
                }, 0.1, 'start');
        }

        if (this.stageEl.length > 0) {

            TweenMax.set(this.stageEl, {
                perspective: 400,
            });

            this.stageHeaderElTl.add('start')
                .staggerFrom(this.stageEl, 0.8, {
                    opacity: 0,
                    y: '50%',
                    skewY: '-2.5deg',
                    delay: 0.2,
                    ease: Expo.easeOut,
                }, 0.075, 'start');
        }
    }

    getCookie(name) {
        const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    setCookie(name, value, days) {
        const d = new Date;
        d.setTime(d.getTime() + 60*60*1000);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    deleteCookie(name) { setCookie(name, '', -1); }

    // region Init

    init() {
        //console.log('Loader init()');
    }

    // endregion

    // region Getters

    /**
     *
     * @returns {*|jQuery|HTMLElement}
     */
    getCursor() {
        return $(this.defaults.cursor);
    }

    getSvgHand() {
        return $(this.defaults.svgHand);
    }

    getMainLoader() {
        return $(this.defaults.mainLoader);
    }

    getMainLoaderContainer() {
        return $(this.defaults.mainLoaderContainer);
    }

    getMainLoaderLogo() {
        return $(this.defaults.mainLoaderLogo);
    }

    getMainLoaderImg() {
        return $(this.defaults.mainLoaderImg);
    }

    getMainLoaderCurtain() {
        return $(this.defaults.mainLoaderCurtain);
    }

    getInitialColorEl() {
        return $(this.defaults.initialColorEl);
    }

    // endregion

    // region Methods

    initLoader() {
        // OPTIONS VARS
        const initScale = 1.1;
        const speed = 0.4;

        // SET DEFAULTS
        // TweenMax.set(this.getMainLoaderContainer(), {
        //     scale: initScale,
        // });

        // TweenMax.set($('#page-wrapper'), {
        //     autoAlpha: 0,
        //     y: 60,
        // });


        // RANDOMIZE LOADER SLIDES
        const $slides = this.getMainLoaderImg();
        // const $slidesNo = $slides.length;
        // $slides.toArray().sort(() => 0.5 - Math.random());

        // this.stageHeaderMenuElTl = new TimelineMax({
        //     paused: true,
        // });

        // DEFINE LOADER TIMELINE
        this.loaderTl = new TimelineMax({
            paused: true,
            onStart: () => {
                exitEnter.enterSet();

                // if (stageMenuEl.length > 0) {
                //
                //     this.stageHeaderMenuElTl.add('start')
                //         .staggerFrom(stageMenuEl, 0.8, {
                //             opacity: 0,
                //             delay: 0.2,
                //             ease: Expo.easeOut,
                //         }, 0.075, 'start');
                // }
            },

            onComplete: () => {
                if(this.stagePreTitleEl.length > 0) {
                    this.stagePreHeaderTl.play();
                }

                if(this.stageSplitTextEl.length > 0) {
                    this.stageHeaderTl.play();
                }

                if(this.stageEl.length > 0) {
                    this.stageHeaderElTl.play();
                }

                // if(stageMenuEl.length > 0) {
                //     this.stageHeaderMenuElTl.play();
                // }

                // TweenMax.to($('#page-wrapper'), 0.8, {
                //     autoAlpha: 1,
                //     y: 0,
                //     ease: Power4.easeOut,
                //     onStart: () => {
                //
                //
                //     },
                //     onComplete: () => {
                //         TweenMax.set($('#page-wrapper'), {
                //             clearProps: "all",
                //         });
                //     },
                // });

                exitEnter.enter(0.4);

                TweenMax.to(this.getMainLoader(), 1, {
                    autoAlpha: 0,
                    onStart: () => {

                    },
                    onComplete: () => {
                        // this.getMainLoader().remove();
                        $('html').removeClass('is-locked');
                    },
                });
            },
        });

        // DEFINE TIMELINE TIMELINiE
        this.timelineTl = new TimelineMax({
            paused: true,
        });

        const totalDuration = 6.4;
        let initColor = this.getInitialColorEl().data('init-color');

        if (initColor === undefined) {
            initColor = '#ffffff';
        }

        // ADD TWEENS TO LOADER TIMELINE
        this.loaderTl
            .add('start')
            .to(this.getMainLoaderCurtain(), totalDuration, {
                width: '100%',
                force3D: true,
                backgroundColor: this.getInitialColorEl(),
                ease: Power0.easeNone,
            }, 'start')
            // .to(this.getMainLoaderContainer(), totalDuration, {
            //     scale: 1.4,
            //     ease: Power0.easeNone,
            //     force3D: true,
            // }, 'start')
            .staggerTo($slides, 1.4, {
                scale: 1,
                // autoAlpha: 1,
                force3D: true,
                ease: Power0.easeNone,
                cycle: {
                    // zIndex(index) {
                    //     return (index + 1);
                    // },
                },
                onStart(tween) {
                    TweenMax.to($(tween.target), 0.4, {
                        autoAlpha: 1,
                    });
                },
                onComplete(tween) {

                },
                onStartParams: ["{self}"],
                onCompleteParams: ["{self}"],
            }, 1, 'start')
            .to(this.getMainLoaderCurtain(), 1.2, {
                y: 0,
                force3D: true,
                ease: Expo.easeInOut,
            });

        //console.log(this.loaderTl.duration());

        // ADD TWEENS TO LOADER TIMELINE
        // ANIMATE LOADER TIMELINE PROGRESS WITH TIMELINE
        this.timelineTl.to(this.loaderTl, totalDuration, {
            progress: 1,
            force3D: true,
            ease: Power1.easeIn,
        });

        TweenMax.to(this.getMainLoaderLogo(), 0.2, {
            autoAlpha: 1,
            ease: Power4.easeOut,
            onComplete: () => {
                this.timelineTl.play();
            },
        });
    }

    cursorMoveDom (e) {
        mousePosition.x = e.x;
        mousePosition.y = e.y;
    }

    initLoaderSimplified() {

        if (this.wordAnimationEffect) {
            //this.wordAnimationEffect.show();
        }

        // DEFINE LOADER TIMELINE
        this.loaderTl = new TimelineMax({
            paused: true,
            onStart: () => {
                exitEnter.enterSet();
                // start track mouse pointer

                if (this.getCursor().length > 0) {
                    window.addEventListener("mousemove", this.cursorMoveDom);
                }
            },

            onComplete: () => {
                if(this.stagePreTitleEl.length > 0) {
                    this.stagePreHeaderTl.play();
                }

                if(this.stageSplitTextEl.length > 0) {
                    this.stageHeaderTl.play();
                }

                if(this.stageEl.length > 0) {
                    this.stageHeaderElTl.play();
                }

                exitEnter.enter(0.4);

                // mouse position
                gsap.set(this.getMainLoader(), {
                    height: this.getMainLoader().outerWidth()+'px',
                    xPercent: -50,
                    yPercent: -50,
                    x: '50%',
                    y: '50%',
                });
                const xSet = gsap.quickSetter(this.getMainLoader(), "x", "px");
                const ySet = gsap.quickSetter(this.getMainLoader(), "y", "px");

                gsap.ticker.add(() => {
                    const dt = 1.0 - Math.pow(1.0 - mouseSpeed, gsap.ticker.deltaRatio());

                    position.x += (mousePosition.x - position.x) * dt;
                    position.y += (mousePosition.y - position.y) * dt;

                    xSet(position.x);
                    ySet(position.y);
                });
                // end mouse position

                TweenMax.to(this.getMainLoader(), 0.3, {
                    //autoAlpha: 0,
                    backgroundColor: this.getInitialColorEl().data('cursor-init-color'),
                    height: '41px',
                    width: '41px',
                    borderRadius: '100%',
                    ease: Power0.easeOut,
                    onStart: () => {
                        TweenMax.to([this.getMainLoaderContainer(), this.getMainLoaderLogo(), this.getMainLoaderCurtain()], 0.2, {
                            transformOrigin: "center top",
                            scale: 0,
                            autoAlpha: 0,
                            ease: Power4.easeOut,
                        })
                    },
                    onComplete: () => {
                        // delete event listener
                        if (this.getCursor().length > 0) {
                            window.removeEventListener("mousemove", this.cursorMoveDom);

                            TweenMax.to(this.getMainLoader(), 0, {
                                autoAlpha: 0,
                            });
                            TweenMax.to(this.getCursor(), 0, {
                                autoAlpha: 1,
                                left: mousePosition.x+'px',
                                top: mousePosition.y+'px'
                            });
                        }

                        $('html').removeClass('is-locked');

                        if (this.getSvgHand().length > 0) {
                            //this.getSvgHand().addClass('is-visible');
                        }

                        const urlString = window.location.href;
                        const hash = $(location).attr('hash');

                        if (hash.length && hash === '#apply-now') {
                            TweenMax.to($('html, body'), 1.4, {
                                scrollTop: $('.js-apply-now').offset().top,
                                ease: Expo.easeInOut,
                            });
                        }
                    },
                });
            },
        });

        // DEFINE TIMELINE TIMELINiE
        this.timelineTl = new TimelineMax({
            paused: true,
        });

        const totalDuration = 1.2;
        let initColor = this.getInitialColorEl().data('init-color');

        if (initColor === undefined) {
            initColor = '#ffffff';
        }

        // ADD TWEENS TO LOADER TIMELINE
        this.loaderTl
            .add('start')
            .to(this.getMainLoaderCurtain(), totalDuration, {
                width: '100%',
                force3D: true,
                backgroundColor: initColor,
                ease: Power0.easeNone,
            }, 'start')
            .to(this.getMainLoaderCurtain(), 1, {
                y: 0,
                force3D: true,
                ease: Expo.easeInOut,
            });

        // ADD TWEENS TO LOADER TIMELINE
        // ANIMATE LOADER TIMELINE PROGRESS WITH TIMELINE
        this.timelineTl.to(this.loaderTl, totalDuration, {
            progress: 1,
            force3D: true,
            ease: Power1.easeIn,
        });

        TweenMax.to(this.getMainLoaderLogo(), 0.2, {
            autoAlpha: 1,
            ease: Power4.easeOut,
            onComplete: () => {
                this.timelineTl.play();
            },
        });

    }
    // endregion
}
