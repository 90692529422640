import { TweenMax } from 'gsap'

export default class SliderBlock {
    constructor() {
        const _defaults = {
            sliderBlock: '.js-slider-block',
            sliderContent: '.js-block-slider-content',
            sliderImage: '.js-block-slider-image',
            sliderPrevious: '.js-block-slider-previous',
            sliderNext: '.js-block-slider-next',
            sliderPagination: '.js-block-slider-pagination',
        };

        this.defaults = Object.assign({}, _defaults);

        if (this.getSliderBlocks().length > 0) {
            this.initSliders();
        }
    }

    init() {

    }

    // region Getters
    getSliderBlocks() {
        return document.querySelectorAll(this.defaults.sliderBlock);
    }

    // endregion


    // region Slider

    initSliders() {
        const interleaveOffset = 0.5;

        for (let sliderBlock of this.getSliderBlocks()) {
            const sliderContent = sliderBlock.querySelector(this.defaults.sliderContent);
            const sliderImage = sliderBlock.querySelector(this.defaults.sliderImage);
            const prev = sliderBlock.querySelector(this.defaults.sliderPrevious);
            const next = sliderBlock.querySelector(this.defaults.sliderNext);

            if (sliderBlock.querySelectorAll('.swiper-slide.js-image-slide').length > 1) {

                const imageSwiper = new Swiper(sliderImage, {
                    loop: true,
                    speed: 800,
                    watchSlidesProgress: true,
                    slidesPerView: 'auto',
                    pagination: {
                        el: '.js-slider-block-pagination',
                        type: 'fraction',
                    },
                    loopedSlides: 50,
                    on: {
                        progress() {
                            const swiper = this;

                            for (let i = 0; i < swiper.slides.length; i++) {
                                const slideProgress = swiper.slides[i].progress;
                                const innerOffset = swiper.width * interleaveOffset;
                                const innerTranslate = slideProgress * innerOffset;

                                TweenMax.set(swiper.slides[i].querySelector(".js-slide-inner"), {
                                    x: innerTranslate,
                                });
                            }
                        },
                        setTransition(speed) {
                            const swiper = this;
                            for (let i = 0; i < swiper.slides.length; i++) {
                                swiper.slides[i].style.transition = `${speed}ms`;
                                swiper.slides[i].querySelector(".js-slide-inner").style.transition =
                                    `${speed}ms`;
                            }
                        },
                    },
                });

                const contentSwiper = new Swiper(sliderContent, {
                    loop: true,
                    speed: 800,
                    slidesPerView: 'auto',
                    slideToClickedSlide: true,
                    loopedSlides: 50,
                    grabCursor: false,
                    effect: "fade",
                    allowTouchMove: false,
                    fadeEffect: {
                        crossFade: true
                    },
                    autoplay: {
                        delay: 12000,
                        disableOnInteraction: false,
                    },
                    navigation: {
                        prevEl: prev,
                        nextEl: next,
                    },
                    controller: {
                        control: imageSwiper
                    },
                    breakpoints: {
                        // when window width is >= 480px
                        480: {
                            allowTouchMove: true,
                        },
                    }
                });
            }
        }
    }

    // endregion
}
