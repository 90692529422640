import ImagesAnimation from "../../ImagesAnimation";
import ChangeColor from "../../ChangeColor";
import ExitEnter from "../../ExitEnter";
import Navigation from "../../Navigation";
import OnScrollAnimations from "../../OnScrollAnimations";
import LazyLoad from "vanilla-lazyload";
import ClipNavigation from "../../ClipNavigation";
//import { midnight } from '../../../bundles/midnight.jquery'
//import Cursor from "../../Cursor";
import MorphingButton from "../../MorphingButton";
import Forms from "../../Forms";
import ContactForm from "../../ContactForm";
//import HideOnScrollDown from "../../HideOnScrollDown";

const morphingButton = new MorphingButton();
const colorChange = new ChangeColor();
const exitEnter = new ExitEnter();
const navigation = new Navigation();
const onScrollAnimations = new OnScrollAnimations();
const clipNavigation = new ClipNavigation();
//const cursor = new Cursor();
const imagesAnimation = new ImagesAnimation();
const forms = new Forms();
const contactForm = new ContactForm();
//const hideOnScrollDown = new HideOnScrollDown();

const lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy-load"
});

export default class initBarbaWorkSingle {

    constructor() {
        this.page = this.extend();
        this.page.init();
    }

    extend() {
        return Barba.BaseView.extend({
            namespace: 'work-single',
            onEnter() {
                console.log('workSingle onEnter');

                colorChange.destroy();
                onScrollAnimations.destroy();
                navigation.init();
                clipNavigation.clipNavigation();

                // lazy load update on page change
                lazyLoadInstance.update();
            },
            onEnterCompleted() {
                console.log('workSingle onEnterCompleted');

                colorChange.attach();
                onScrollAnimations.attach();
                //cursor.init();
                morphingButton.init();
                imagesAnimation.init();
                forms.init();
                contactForm.init();
                //hideOnScrollDown.init();
                //sendPageView();

                setTimeout(() => {
                    var targetElement = document.querySelector('.js-prev-case');
                    if (!targetElement) {
                        return;
                    }
                    Barba.Prefetch.onLinkEnter({
                        target: targetElement,
                    });

                    targetElement = document.querySelector('.js-next-case');
                    if (!targetElement) {
                        return;
                    }
                    Barba.Prefetch.onLinkEnter({
                        target: targetElement,
                    });
                }, 300);
            },
            onLeave() {
                //exitEnter.exit();
            },
            onLeaveCompleted() {
            },
        });
    }
}
